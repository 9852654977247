<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-n" *ngFor="let indx  of totRow">       
      <div class="row" >
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2" *ngFor="let card  of (col | datafilter:topserviceProviders:indx)">
          <app-personalcard [cardInfo]="card"></app-personalcard>     
        </div>
      </div>       
  </div>
</div>

