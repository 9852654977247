import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
//import { MatTableDataSource } from '@angular/material';
import { UsefulLinkModel } from '../../model/useful-link-model';
import { AdminService } from '../../services/Admin/admin.service';
import { CoreService } from '../../services/core/core.service';
import { IpServiceService } from '../../services/ip-service.service';

@Component({
  selector: 'app-carrierlink',
  templateUrl: './carrierlink.component.html',
  styleUrls: ['./carrierlink.component.css']
})
export class CarrierlinkComponent implements OnInit {
  private ipAddress?: string;
  data: any | undefined;
  errMessage: string | undefined;
  constructor(private coreService: CoreService, private ipService: IpServiceService, private adminService: AdminService) { }

  ngOnInit(): void {
    this.getIP();
  }
  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      this.getCarrierLink();

    });
  }
  getCarrierLink() {
    this.adminService.getCarrierLink(this.ipAddress).subscribe((response: UsefulLinkModel[]) => {
      this.data = new MatTableDataSource(response);
      /* this.data.paginator = null;*/
    }, err => {
      this.errMessage = "Error occured";
    });
  }
}
