import { ICommunicationFilter } from "../model/i-communication-filter";
import { MailType } from "../constants/api-portal-constant";

export class CommunicationFilter implements ICommunicationFilter {
  pageNumber: number;
  pageSize: number;
  mailBoxType: string;
  constructor() {
    this.mailBoxType = MailType.Inbox;
    this.pageNumber = 1;
    this.pageSize = null;
  }

}
