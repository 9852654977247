import { Component, OnInit } from '@angular/core';
//import { MatDialogRef } from '@angular/material';
import { CoreService } from '../../services/core/core.service';
import { Termsandconditionmodel } from '../../model/termsandconditionmodel';
import { IpServiceService } from '../../services/ip-service.service';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { SpecialLookup } from '../../model/special-lookup';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  public termsandconditionmodel: Termsandconditionmodel;
  private specialLookup: SpecialLookup;
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    private coreService: CoreService,
    private ipService: IpServiceService,
    private portalnInfo: PortalninformationService
  ) { }

  ngOnInit() {
    this.getIP(); 
  }

  actionFunction() {
  
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

  getTermsAndConditions() {
    this.coreService.getTermsAndConditions(this.specialLookup.actualCountryCode).subscribe((data: Termsandconditionmodel) => {
      this.termsandconditionmodel = data;
      
    });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.getPortalInfo(res.ip);
    
    });
  }

  getPortalInfo(ip: string) {
   
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
        this.getTermsAndConditions();
      });
  }
}
