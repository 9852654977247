import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Filters } from '../../implementations/filters';
import { CommunicationFilter } from '../../implementations/communication-filter';
import { ComposeMail } from '../../model/compose-mail';
import { AuthToken } from '../../constants/api-portal-constant';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private baseUrl: string;
  private routePrefix: string = 'api/email';

  constructor(private httpClient: HttpClient, private router: Router, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl;
  }

  getMailBox(filter: CommunicationFilter): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
     
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/mailbox", filter, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      });
    }
    else {
      return null;
    }

  }

  getMail(mailboxType: string, messageId: number) {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {    
      return this.httpClient.get(this.baseUrl + this.routePrefix + "/" + messageId + "/" + mailboxType);

    }
    else {
      return null;
    }
  }
  sendEmail(composeMail: any): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/send-email", composeMail, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }
  }

  contactUs(composeMail: any): any {
   
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/contact-us", composeMail, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }


  getEmailAddress(lookupValue: string) {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.get(this.baseUrl + this.routePrefix + "/" + lookupValue);

    }
    else {
      return null;
    }
  }
}
