<br /><br />
<div class="screen-width">
  <app-search (dataelement)="receiveData($event)" (datafilter)="receiveFilter($event)" spinner="spinner"></app-search>
</div>
<div class="mat-elevation-z8">
  <table mat-table class="mat-elevation-z8 border-0 table-striped table-bordered" [dataSource]="dataSource">
    <ng-container matColumnDef="firstName">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont">Name</th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element">{{element.firstName}}</td>
      </tr>
    </ng-container>

    <ng-container matColumnDef="expertise">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont"> Expertise </th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element"> {{element.subjectName}} </td>
      </tr>
    </ng-container>


    <ng-container matColumnDef="education">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont">Education</th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element"> {{element.educationDegree}} </td>
      </tr>
    </ng-container>

    <ng-container matColumnDef="city">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont">City</th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element"> {{element.city}} </td>
      </tr>
    </ng-container>

    <ng-container matColumnDef="member">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont">Member(since) </th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element"> {{element.memberShip}} </td>
      </tr>
    </ng-container>

    <ng-container matColumnDef="rating">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont">Rating</th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element">{{element.ratingValue}}</td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="id">
      <tr>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element">
          <button class="btn btn-primary" (click)="navigate(element.id);">Details</button>
        </td>
      <tr>
    </ng-container>
    <ng-container matColumnDef="tag">
      <tr>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element">
          <button class="btn btn-primary {{element.tagged}}" (click)="addTag(element.id);" title="Tag"><i class="fas fa-tag"></i></button>
        </td>
      <tr>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <mat-paginator [length]="length"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="pageSizeOptions"
                 (page)="changePage($event)">
  </mat-paginator>
</div>









