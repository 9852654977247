<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngFor="let video  of featureLectures"> 
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-2">
        <app-video [video]="video"></app-video>
      </div>
    </div>
  </div>
</div>

