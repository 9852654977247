import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AdminService } from '../../services/Admin/admin.service';
import { IpServiceService } from '../../services/ip-service.service';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';

@Component({
  selector: 'app-jobsbycountry',
  templateUrl: './jobsbycountry.component.html',
  styleUrls: ['./jobsbycountry.component.css']
})
export class JobsbycountryComponent implements OnInit {
  jobsByCountry: any;
  constructor(private portalnInfo: PortalninformationService,
    private ipService: IpServiceService, private title: Title,
    private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.getJobsByCountry();
  }
  getJobsByCountry() {
    this.adminService.getJobsByCountry()
      .subscribe(response => {
        this.jobsByCountry = response;

      });
  }
}
