import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Filters } from '../../implementations/filters';
import { AuthToken } from '../../constants/api-portal-constant';
import { Tag } from '../../model/tag';
import { BasicProfile } from '../../model/basic-profile';



@Injectable({
  providedIn: 'root'
})
export class ProvidersService {
  private baseUrl: string;
  private routePrefix: string = 'api/profile';

  constructor(private httpClient: HttpClient, private router: Router, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl;
  }
  getProviers(filter: Filters): any {
 
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/provider", filter, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      });
    }
    else {
      return null;
    }
  }

  getProvierProfile(id): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.get(this.baseUrl + this.routePrefix + "/provider/" + id);
    }
    else {
      return null;
    }
  }

  getProfile(): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.get(this.baseUrl + this.routePrefix + "/profile");
    }
    else {
      return null;
    }
  }

  getServiceProviderProfile(userId: number): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.get(this.baseUrl + this.routePrefix + "/service-provider-profile/" + userId);
    }
    else {
      return null;
    }
  }
  saveHire(composeMail: any): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/service-providers-student", composeMail, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }
  }
  savePayment(payment: any) {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/payment", payment, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }
  }
  stopService(stopServiceModel: any): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/stop-service", stopServiceModel, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }
  }
  saveReview(review: any): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/rating", review, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }
  }
  addTag(model: Tag) {
    return this.httpClient.post(this.baseUrl + this.routePrefix + "/tagging", model, {
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    });
  }

  getHiringHistory(ip: string) {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/hiring-history/" + ip);
  }

  getReviews(basicProfile: BasicProfile) {
    return this.httpClient.post(this.baseUrl + this.routePrefix + "/questionnaire", basicProfile, {
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    });
  }

  getJobProviders() {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/job-provider");
  }

  getTopServiceProviders(ipAddress: string) {
    //debugger
    const idToken = localStorage.getItem(AuthToken.AuthTokenKey);
    if (idToken) {
      return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/top-providerbyUser/${ipAddress}`);
    }
    else {
      return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/top-provider/${ipAddress}`);
    }

  }

  getCarousel(ip: string) {

    const idToken = localStorage.getItem(AuthToken.AuthTokenKey);
    if (idToken) {
      return this.httpClient.get(this.baseUrl + this.routePrefix + "/carousel-by-user/" + ip);
    }
    else {
      return this.httpClient.get(this.baseUrl + this.routePrefix + "/carousel/" + ip);
    }

  }
}
