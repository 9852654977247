
<div class="card w-100">
  <table class="table-responsive">
    <tr>
      <td *ngIf="cardInfo?.imagePath && !cardInfo?.image">
        <img [src]="cardInfo?.imagePath" class="img-responsive" alt="{{cardInfo?.fullName}}" style="width:70px;height:100px;" />
      </td>
      <td *ngIf="cardInfo?.image">
        <img [src]="(cardInfo?.image)" class="img-responsive" alt="{{cardInfo?.fullName}}" style="width:70px;height:100px;" />
      </td>
      <td>
        <h1 class="fullname">
          <a [routerLink]="['/serviceprofile']" [queryParams]="{userId:cardInfo?.id}">
            {{cardInfo?.fullName}}
          </a>
        </h1>
        <table>
          <tr>
            <td style="font-weight:bold">
              {{cardInfo?.categoryName}}
            </td>
          </tr>

          <tr>
            <td class="title">
              {{cardInfo?.educattion}}
            </td>
          </tr>
          <tr *ngIf="cardInfo?.institute !=null">
            <td>
              <p>{{cardInfo?.institute}}</p>
            </td>
          </tr>
          <tr *ngIf="cardInfo?.ratingAvg>0">
            <td>
              <b>Rating:</b> {{cardInfo?.ratingAvg}} out of 5
            </td>
          </tr>
          <tr *ngIf="cardInfo?.city || cardInfo?.districtName">
            <td>
              {{cardInfo?.city}}, {{cardInfo?.districtName}}
            </td>
          </tr>
        </table>
      </td>
    </tr>
    
  </table>
  
 
  
 
 
</div>

