import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PortalninformationService {
  private baseUrl: string;
  private routePrefix: string = 'api/startup';

  constructor(private httpClient: HttpClient, private router: Router, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl;
  }
  getPortalInformation(ip: string) {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/" + ip);
  }
  getIHomePageInformation(countryCode: string) {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/home/" + countryCode);
  }

  getHomePageEmptySpace() {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/empty-space`);
  }
}
