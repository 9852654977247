import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '../../model/question-model';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
  max: number = 5;
  @Input()
  questionId: number;
  @Input()
  subjectId: number;
  @Output() onRating = new EventEmitter<QuestionModel>();

  public maxItem: any[] ;
  public ratedCount: number;
  private questionModel: QuestionModel;
  constructor() {
    this.ratedCount = 0;
  }

  ngOnInit() {
    this.maxItem = [];
    for (let i = 0; i < this.max; i++) {
      this.maxItem.push(i + 1);
    }
  }

  public toggleRating(subId: number, qsId: number, s: number): void {
    this.ratedCount = s;
    var qs = { questionId: qsId, subjectId: subId, ratingValue:s };
    this.questionModel = qs;
   

    this.onRating.emit(this.questionModel);
  }
}

