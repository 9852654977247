import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'aboutus', loadChildren: () => import('./components/aboutus/aboutus.module').then(m => m.AboutusModule) },
  { path: 'trainee', loadChildren: () => import('./components/trainee/trainee.module').then(m => m.TraineeModule) },
  { path: 'edit', loadChildren: () => import('./components/editpersonalinfo/editpersonalinfo.module').then(m => m.EditpersonalinfoModule) },
  { path: 'termsAndCondition', loadChildren: () => import('./components/adminterms-and-conditions/adminterms-and-conditions.module').then(m => m.AdmintermsAndConditionsModule) },
  {
    path: 'contact-us', loadChildren: () => import('./components/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  { path: 'faq', loadChildren: () => import('./components/faq/faq.module').then(m => m.FaqModule) },
  { path: 'loginapi', loadChildren: () => import('./components/loginapi/loginapi.module').then(m => m.LoginapiModule) },
  //{
  //  path: 'servieprovider', loadChildren: () => import('./components/servceprovider/servceprovider.module').then(m => m.ServceproviderModule)
  //}
  { path: 'hiringHistory', loadChildren: () => import('./components/history/history.module').then(m => m.HistoryModule) },
  //{ path: 'stopservice', loadChildren: () => import('./components/stopservice/stopservice.module').then(m => m.StopserviceModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
