import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';

@Component({
  selector: 'app-fblivechat',
  templateUrl: './fblivechat.component.html',
  styleUrls: ['./fblivechat.component.css']
})
export class FblivechatComponent implements OnInit {

  constructor(
    private facebookService: FacebookService
  ) { }

  ngOnInit() {
    this.initFacebookService();
  }
  private initFacebookService(): void {
    //debugger;
    const initParams: InitParams =
    {
      xfbml: true, version: 'v3.2'
      //appId: '101498921669063'
    };
    this.facebookService.init(initParams);
    //this.facebookService.login()
    //  .then((response: LoginResponse) => console.log(response))
    //  .catch((error: any) => console.error(error));
  }

}
