import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizePipe'
})
export class FileSizePipePipe implements PipeTransform {

  transform(size: number, unit: string): string {
   
    var convertedSize = "";
    switch (unit) {
      case "KB":
        convertedSize = `${(size / 1024).toFixed(2)}${unit}`;
        break;
      case "MB":
        convertedSize = `${(size / (1024 * 1024)).toFixed(2)}${unit}`;
        break;
    }
    return convertedSize;
  }

}
