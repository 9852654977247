import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { IpServiceService } from '../../services/ip-service.service';
@Component({
    selector: 'app-carousel-dynamic',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  constructor(private coreService: CoreService, private ipService: IpServiceService) {

  }
  event_list: any = [];
 
  upcoming_events = this.event_list;

  getCarousel(ip:string) {

    this.coreService.getCarousel(ip)
      .subscribe((data: any) => {
       
        if (data != null) {
          for (var index in data) {
            this.push_data_event_list(data[index]);
          }
        }
      });
  }

  push_data_event_list(data: any) {
    if (data != null) {
      //debugger
      this.event_list.push({
        event: ' Event 1',
        description: data.description,
        img: data.img,
       
      });
    }
  }

  ngOnInit(): void {
    this.getIP();
    
  }
  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.getCarousel(res.ip);     
    });
  }
}
