import { Component, OnInit, Input, EventEmitter,Output } from '@angular/core';
import { Filters } from '../../implementations/filters';
import { ServiceProvider } from '../../model/service-provider';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { CoreService } from '../../services/core/core.service';
import { Category } from '../../model/category';
//import { MatSelectChange, MatExpansionPanel } from '@angular/material';
import { UserType, ControlConstant } from '../../constants/api-portal-constant';
import { IpServiceService } from '../../services/ip-service.service';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { SpecialLookup } from '../../model/special-lookup';
import { SearchModel } from '../../model/search-model';
import { Subjects } from '../../model/subjects';
import { DivisionsModel } from '../../model/divisions-model';
import { DistrictsModel } from '../../model/districts-model';
import { ThanasModel } from '../../model/thanas-model';
import { LabelByCountryModel } from '../../model/label-by-country-model';
import { SetBrowserService } from '../../services/set-browser.service';
import { LocalCookieService } from '../../services/cookie/local-cookie.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  panelOpenState: boolean = false;
  filter: Filters = new Filters();;
  data: ServiceProvider[];
  public allCategory: Category[];
  public selectedCategory: number;
  private selectedSubjects: number[];
  private selectedEducation: number[];
  private selectedInstitution: number[];
  private ipAddress: string;
  private specialLookup: SpecialLookup;
  public searchModel: SearchModel;
  myPanel: MatExpansionPanel;
  public subjects: Subjects[];
  public allDivisions: DivisionsModel[];
  public allDistrict: DistrictsModel[];
  public allThana: ThanasModel[];
  public labelByCountry: LabelByCountryModel[];
  public divisionId: number;
  public districtId: number;
  public thanaId: number;
  public userType: string;
  public roadName: string;
  public thana: string;
  public division: string;
  public district: string;
  public districtToolbar: string;
  public divisionToolbar: string;
  public roadnumToolbar: string;
  public thanaToolbar: string;
  public 
  constructor(private providerService: ProvidersService,
              private coreService: CoreService,
              private ipService: IpServiceService,
              private setbrowserService: SetBrowserService,
    private portalnInfo: PortalninformationService,
    private localCookieService: LocalCookieService) {
    
  }

  ngOnInit() {
    this.userType = this.localCookieService.getRole();
    this.getIP();    
  }

  @Input()
  successMsg: string;
 
  @Input()
  errMsg: string;

  @Output() dataelement: EventEmitter<ServiceProvider[]> = new EventEmitter<ServiceProvider[]>();
 

  getProviders() {

    this.filter.education = this.selectedEducation;
    this.filter.educationInstitute = this.selectedInstitution;
    this.filter.subjects = this.selectedSubjects;
    //this.spinner.show();
    this.providerService.getProviers(this.filter).subscribe(response => {
      //this.spinner.hide();
      if (response != null) {
        this.data = response.serviceList;
        this.dataelement.emit(this.data);
      }
    },
      _error => {
        var err = _error;
      });
  }

  getSearchCriteria() {
    this.coreService.getSearchCriteria(this.specialLookup.actualCountryCode)
      .subscribe((data: SearchModel) => {
        //debugger;
        this.searchModel = data;
        
      });
  }
  searh(myPanel: MatExpansionPanel) {
   
    this.filter.category = this.selectedCategory;
    this.filter.divisionId = this.divisionId;
    this.filter.districtId = this.districtId;
    this.filter.thanaId = this.thanaId; 
    this.getProviders();
    
    if (myPanel.expanded) {
      myPanel.expanded = false;
    }
  }

  changeCategory(selvalue: MatSelectChange) {
    this.selectedCategory = selvalue.value;
    this.GetSubjectCategory();
  }
  changeInstitution(selvalue: MatSelectChange) {
    
    this.selectedInstitution = selvalue.value;
  }

  changeEducation(selvalue: MatSelectChange) {

    this.selectedEducation = selvalue.value;
  }
  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      this.filter.ip = res.ip;
      this.getPortalInfo(this.ipAddress);
      this.getProviders(); 
    });
  }
  getPortalInfo(ip: string) {
      //debugger;
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
        this.getSearchCriteria();
        this.GetAllDivisions(this.specialLookup.actualCountryCode);
        this.getLabelByCountry(this.specialLookup.actualCountryCode);
      });
  }

  GetSubjectCategory() {
    this.coreService.GetSubjectCategory(this.selectedCategory, "None", this.specialLookup.actualCountryCode, this.userType)
      .subscribe((data: Subjects[]) => {
        this.subjects = data;
      });
  }

  changeSubject(selvalue: MatSelectChange) {
    this.selectedSubjects = selvalue.value;
  }

  GetAllDivisions(countryCode: string) {

    this.coreService.GetAllDivisions(countryCode)
      .subscribe((data: DivisionsModel[]) => {
        this.allDivisions = data;

      });
  }

  getDistrict() {
    this.filter.divisionId = this.divisionId;
    this.coreService.GetAllDistricts(this.divisionId)
      .subscribe((data: DistrictsModel[]) => {
        this.allDistrict = data;

      });
  }

  getAllThana() {
    this.filter.districtId = this.districtId;
    this.coreService.GetAllThana(this.districtId)
      .subscribe((data: ThanasModel[]) => {
        this.allThana = data;
      });
  }

  changeDivision(event: any) {
    this.divisionId = event.value;
    this.getDistrict();
  }

  changeDistrict(event: any) {
    this.districtId = event.value;
    this.getAllThana();
  }
  changeThana(event: any) {
    this.thanaId = event.value;
  }

  getLabelByCountry(countryCode: string) {
    this.coreService.getLabelByCountry(countryCode, this.userType)
      .subscribe((data: LabelByCountryModel[]) => {
        this.labelByCountry = data;
        this.roadName = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.RoadnumName)[0].labelName;

        this.district = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.District)[0].labelName;

        this.division = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.Division)[0].labelName;
       
        this.thana = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.Thana)[0].labelName;

        this.districtToolbar = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.DistrictToolbar)[0].labelName;

        this.divisionToolbar = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.DivisionToolbar)[0].labelName;

        this.thanaToolbar = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.ThanaToolbar)[0].labelName;

        this.roadnumToolbar = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.RoadnumToolbar)[0].labelName;
      },
        error => {
          var err = error;
        });
  }
}
