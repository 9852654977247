import { Component, OnInit, Input } from '@angular/core';
import { Toppers } from '../../model/toppers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personalcard',
  templateUrl: './personalcard.component.html',
  styleUrls: ['./personalcard.component.css']
})
export class PersonalcardComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigate(id) {
    this.router.navigate(["/serviceprofile"], { queryParams: { userId: id } });
  }

  @Input()
  cardInfo: Toppers;
}
