<div>
  <ul>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/loginapi"]'>
        LogIn
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/join"]'>
        Sign up
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/trainee"]'>
        Register as Sevice Provider
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/servieprovider"]'>
        Profiles
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/serviceprofile"]'>
        Profile Viewer
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/contact-us"]'>
        Contact us
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/conversation"]'>
        Email Conversation
      </a>
    </li>

    <li>
      <a class="nav-link text-dark" [routerLink]='["/aboutus"]'>
        About us
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/hireme"]'>
        Hire Me
      </a>
    </li>

    <li>
      <a class="nav-link text-dark" [routerLink]='["/hiringHistory"]'>
        Hiring history
      </a>
    </li>

    <li>
      <a class="nav-link text-dark" [routerLink]='["/reviews"]'>
        Reviews
      </a>
    </li>

    <li>
      <a class="nav-link text-dark" [routerLink]='["/rating"]'>
        Rate your service provider
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/stopservice"]'>
        Stop your service
      </a>
    </li>

    <li>
      <a class="nav-link text-dark" [routerLink]='["/homepageinfo"]'>
        Landing Page
      </a>
    </li>
    <li>
      <a class="nav-link text-dark" [routerLink]='["/homepageinfo"]'>
        Landing Page
      </a>
    </li>

    <li>
      <a class="nav-link text-dark" [routerLink]='["/faq"]'>
        Frequently asked question
      </a>
    </li>
  </ul>
</div>
