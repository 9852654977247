import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalRole } from '../../constants/api-portal-constant';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-starpup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.css']
})
export class StartupComponent implements OnInit {
   
    constructor(private router: Router, private Activatedroute: ActivatedRoute, private title:Title) {
       

  }

  ngOnInit() {
    this.title.setTitle("Start-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
  }
  navigateServiceProvider() {
    this.router.navigate(["/trainee"], { queryParams: { userType: PortalRole.JobProvider } });
  }

  navigateJobSeeker() {
    this.router.navigate(["/trainee"], { queryParams: { userType: PortalRole.JobSeeker } });
  }
}
