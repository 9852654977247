import { Component, OnInit } from '@angular/core';
import { SpecialLookup } from '../../model/special-lookup';
import { FormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CoreService } from '../../services/core/core.service';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { IpServiceService } from '../../services/ip-service.service';
import { SetBrowserService } from '../../services/set-browser.service';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { ActivatedRoute } from '@angular/router';
import { LocalCookieService } from '../../services/cookie/local-cookie.service';
import { country } from '../../model/country';
import { PaymentOptionModel } from '../../model/payment-option-model';
import { Title } from '@angular/platform-browser';
import { CountryCode, PaymentType } from '../../constants/api-portal-constant';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  public paymentForm: UntypedFormGroup;
  public userId: any;
  public userType: string;
  public specialLookup: SpecialLookup;
  public paymentOptions: PaymentOptionModel[];
  public successMsg: string;
  public errMessage: string;
  public selectedCountryCode: string;
  public country: country[];
  public fullName: string;
  public isSubmitted: boolean;
  public amount: number;
  public promotionAmount: number;
  public billingMonths: number;
  public totalAmount: number;
  public billingOptionName: string;
  public countryCode: string = CountryCode.Bangladesh;
  public isCreditCardOption: boolean = false;
  public isBikashOption: boolean = false;
  private monthlyPayment: number;

  constructor(private fb: UntypedFormBuilder,
    private coreService: CoreService,
    private providersService: ProvidersService,
    private ipService: IpServiceService,
    private setbrowserService: SetBrowserService,
    private portalnInfo: PortalninformationService,
    private activatedroute: ActivatedRoute,
    private localCookieService: LocalCookieService,
   
    private title: Title) {

    this.createForm();
    this.userId = this.activatedroute.snapshot.queryParamMap.get('userId') || 0;
    this.userType = this.localCookieService.getRole();
  }

  ngOnInit() {
    this.title.setTitle("Payment-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.getIP();
    this.getAllCountry();
  }

  createForm() {
    this.paymentForm = this.fb.group({
      fullName: '',
      countryCode: '',
      userType: '',
      id: Number,
      ip: String,
      creditCardNumber: '',
      cvv: '',
      expirationMonth: '',
      expirationYear: '',
      city: '',
      address: '',
      zipcode: '',
      subscriptionOption: '',
      billingOption: '',
      selectedCountryCode: '',
      paymentOpt: '',
      phoneNumber: '',
      totalAmount: Number,
      monthlyPayment: Number
    }); 
  }
  savePayment() {
    // debugger
    this.paymentForm.value.id = this.userId;
    //this.paymentForm.value.selectedCatSubIds = this.selectedCatSubIds;
    //this.paymentForm.value.hireDate = this.selectedMonth + "/" + this.selectedDay + "/" + this.selectedYear;
    this.paymentForm.value.totalAmount = this.totalAmount;
    this.paymentForm.value.monthlyPayment = this.monthlyPayment;
    if (this.paymentForm.value.paymentOpt == PaymentType.Bikash) {
      this.paymentForm.value.creditCardNumber = this.paymentForm.value.phoneNumber;
    }
    else {
      this.paymentForm.value.countryCode = this.selectedCountryCode;
    }

    var isValid = this.getDataValidation();
    let credentials1 = JSON.stringify(this.paymentForm.value);

    var fileData: FormData = new FormData();
    fileData.append('data', credentials1);
    let credentials = fileData;
    if (credentials != undefined && isValid) {
      this.providersService.savePayment(credentials).subscribe(response => {
        let info = (<any>response).success;
        if (info != undefined) {
          this.successMsg = info;
          this.errMessage = null;
        }
        else {
          this.errMessage = (<any>response).errMsg;
          this.successMsg = null;
        }
      }, err => {

      });
    }

  }
  getPortalInfo(ip: string) {
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
        this.getAllAccountFees(this.specialLookup.actualCountryCode);
        this.getBillingOption();
      });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      var ipAddress = res.ip;
      this.getPortalInfo(ipAddress);
    });
  }



  populateField() {

  }

  getAllCountry() {
    this.coreService.getAllCountry()
      .subscribe((data: country[]) => {
        this.country = data; 
      });
  }

  getAllAccountFees(countryCode: string) {
    this.coreService.getAllAccountFees(countryCode)
      .subscribe((data: PaymentOptionModel[]) => {
        this.paymentOptions = data;
      });

  }
  getPaymentOption() {
    switch (this.paymentForm.value.paymentOpt) {
      case "Bikash":
        this.isCreditCardOption = false;
        this.isBikashOption = true;
        break;
      case "CreditCard":
        this.isCreditCardOption = true;
        this.isBikashOption = false;
        break;
    }
  }

  getSubscriptionOption() {
    var billingOp = this.paymentOptions.filter(x => x.billingValue == this.paymentForm.value.subscriptionOption);
    if (billingOp != undefined && billingOp.length > 0) {
      this.amount = billingOp[0].amount;
      this.promotionAmount = billingOp[0].promotionAmount;
      this.billingOptionName = billingOp[0].billingOptionName;
      this.billingMonths = this.paymentForm.value.subscriptionOption;
      this.totalAmount = (this.amount - this.promotionAmount) * this.billingMonths;
      this.monthlyPayment = this.totalAmount / this.billingMonths;
    }
  }

  handleInput(value: any) {
    //debugger

  }

  getBillingOption() {

  }

  getDataValidation() {
    var htmel: HTMLElement;
    var errorMsg: string;
    var isValid: boolean = true;
    var isValidCreditCard = true;
    htmel = document.getElementById("errPayOpt");
    if (this.specialLookup.actualCountryCode == CountryCode.Bangladesh && (this.paymentForm.value.paymentOpt == undefined || !this.paymentForm.value.paymentOpt)) {

      errorMsg = "<span class='alert alert-danger d-block'>Must select payment option</span>";
      this.paymentForm.controls['paymentOpt'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }
    htmel = document.getElementById("subsOption");
    if (this.paymentForm.value.subscriptionOption == undefined || !this.paymentForm.value.subscriptionOption) {

      errorMsg = "<span class='alert alert-danger d-block'>Must select subscription option</span>";
      this.paymentForm.controls['subscriptionOption'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    htmel = document.getElementById("errBillingOpt");
    if (this.paymentForm.value.billingOption == undefined || !this.paymentForm.value.billingOption) {

      errorMsg = "<span class='alert alert-danger d-block'>Must select billing option</span>";
      this.paymentForm.controls['billingOption'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    if (this.specialLookup.actualCountryCode == CountryCode.Bangladesh) {
      switch (this.paymentForm.value.paymentOpt) {
        case "1":///Bikash/Nagad
          htmel = document.getElementById("errPhoneNum");
          if (this.paymentForm.value.phoneNumber == undefined || !this.paymentForm.value.phoneNumber) {
            errorMsg = "<span class='alert alert-danger d-block'>Please enter phone number</span>";
            this.paymentForm.controls['phoneNumber'].setErrors({ 'incorrect': true });
            htmel.innerHTML = errorMsg;
            isValid = false;
          }
          else {
            htmel.innerHTML = "";
          }
          break;
        case "2"://Credit Card
          isValidCreditCard =this.CreditCardValidation();
          break;
      }
    }

    return (isValid && isValidCreditCard);;

  }

  CreditCardValidation() {
    var htmel: HTMLElement;
    var errorMsg: string;
    var isValid: boolean = true;
    htmel = document.getElementById("errCreditCard");
    if (this.paymentForm.value.creditCardNumber == undefined || !this.paymentForm.value.creditCardNumber || this.paymentForm.value.creditCardNumber.length<16) {
      errorMsg = "<span class='alert alert-danger d-block'>Invalid credit card number</span>";
      this.paymentForm.controls['creditCardNumber'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }
    
    htmel = document.getElementById("errCVV");
    if (this.paymentForm.value.cvv == undefined || !this.paymentForm.value.cvv || this.paymentForm.value.cvv.length < 3) {
      errorMsg = "<span class='alert alert-danger d-block'>Invalid security code</span>";
      this.paymentForm.controls['cvv'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    htmel = document.getElementById("errExpiration");
    if (this.paymentForm.value.expirationYear == undefined || !this.paymentForm.value.expirationYear || this.paymentForm.value.expirationYear.length < 6) {
      errorMsg = "<span class='alert alert-danger d-block'>Invalid expiration date</span>";
      this.paymentForm.controls['expirationYear'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }


    htmel = document.getElementById("errFullName");
    if (this.paymentForm.value.fullName == undefined || !this.paymentForm.value.fullName ) {
      errorMsg = "<span class='alert alert-danger d-block'>Enter full name</span>";
      this.paymentForm.controls['fullName'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    htmel = document.getElementById("errAddress");
    if (this.paymentForm.value.address == undefined || !this.paymentForm.value.address) {
      errorMsg = "<span class='alert alert-danger d-block'>Enter credit card billing address</span>";
      this.paymentForm.controls['address'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    htmel = document.getElementById("errCity");
    if (this.paymentForm.value.city == undefined || !this.paymentForm.value.city) {
      errorMsg = "<span class='alert alert-danger d-block'>Enter City</span>";
      this.paymentForm.controls['city'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    htmel = document.getElementById("errZipcode");
    if (this.paymentForm.value.zipcode == undefined || !this.paymentForm.value.zipcode) {
      errorMsg = "<span class='alert alert-danger d-block'>Enter Zipcode</span>";
      this.paymentForm.controls['zipcode'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    htmel = document.getElementById("errCountry");
    if (this.selectedCountryCode == undefined || !this.selectedCountryCode) {
      errorMsg = "<span class='alert alert-danger d-block'>Enter Zipcode</span>";
      this.paymentForm.controls['selectedCountryCode'].setErrors({ 'incorrect': true });
      htmel.innerHTML = errorMsg;
      isValid = false;
    }
    else {
      htmel.innerHTML = "";
    }

    return isValid;
  }
}
