import { Component, Input, OnInit } from '@angular/core';
import { FeatureLectureModel } from '../../model/feature-lecture-model';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  private apiLoaded = false;
  
  @Input() video: FeatureLectureModel;
  constructor() { }

  ngOnInit(): void {
    if (!this.apiLoaded) {
           const tag = document.createElement('script');
           tag.src = 'https://www.youtube.com/iframe_api';
           document.body.appendChild(tag);
          this.apiLoaded = true;
      
    }
  }

}
