<mat-accordion>
  <mat-expansion-panel #myPanel="matExpansionPanel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <i class="fa fa-search btn btn-primary">Expand search panel</i>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-form-field>          
            <mat-select  (selectionChange)="changeDivision($event)" placeholder="{{division}}"
                         class="mdb-select md-form">
              <mat-option>Select</mat-option>
              <mat-option *ngFor="let d of allDivisions" [value]="d.id">{{d.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-form-field>            
            <mat-select (selectionChange)="changeDistrict($event)" placeholder="{{district}}"
                        id="district" class="mdb-select md-form">
              <mat-option>Select</mat-option>
              <mat-option *ngFor="let d of allDistrict" [value]="d.id">{{d.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-form-field>           
            <mat-select (selectionChange)="changeThana($event)"
                        placeholder="{{thana}}" id="thana" class="mdb-select md-form">
              <mat-option>Select</mat-option>
              <mat-option *ngFor="let t of allThana" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-form-field>
            <mat-label>Category</mat-label>
            <mat-select #clientValue (selectionChange)="changeCategory($event)" placeholder="Category" id="category" class="mdb-select md-form">
              <mat-option>Select</mat-option>
              <mat-option *ngFor="let c of searchModel?.allCategory" [value]="c.id">{{c.categoryName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="selectedCategory!=null">
        <div class="col-12 col-sm-6">
          <mat-form-field>
            <mat-label>Subjects</mat-label>
            <mat-select #clientSubject placeholder="Subject" id="subject" (selectionChange)="changeSubject($event)"
                        class="mdb-select md-form" multiple>
              <mat-option *ngFor="let s of subjects" [value]="s.id">{{s.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-form-field>

            <mat-select (selectionChange)="changeEducation($event)" placeholder="Education" id="education" class="mdb-select md-form" multiple>
              <mat-option *ngFor="let c of searchModel?.diploma" [value]="c.id">{{c.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-form-field>
            <mat-select (selectionChange)="changeInstitution($event)" placeholder="Education Institute" id="educationInstitute" class="mdb-select md-form" multiple>
              <mat-option *ngFor="let c of searchModel?.institutions" [value]="c.id">{{c.institutionName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <!--<div class="row">
    <div class="col-12 col-sm-6">
      <mat-form-field>
        <input matInput placeholder="Phone" id="phone">
      </mat-form-field>
    </div>
  </div>-->
      <!--<div class="row">
    <div class="col-12 col-sm-6">
      <mat-form-field>
        <input matInput placeholder="City" id="city">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6">
      <mat-form-field>
        <input matInput placeholder="Address" id="address">
      </mat-form-field>
    </div>
  </div>-->
      <!--<div class="row">
    <div class="col-12 col-sm-6">
      <mat-form-field>
        <input matInput placeholder="Pastal code" id="postalCode">
      </mat-form-field>
    </div>
  </div>-->
    </div>
    <mat-action-row class="pull-left">
      <button class="btn btn-primary pull-left" (click)="searh(myPanel)">Search</button>
    </mat-action-row>
  </mat-expansion-panel>

</mat-accordion>
