<br /><br />
<mdb-card class="cardwith card-css">
  <mdb-card-header class="info-color white-text text-center py-4">
    <h5>
      <strong>Password recover</strong>
    </h5>
  </mdb-card-header>
  <mdb-card-body class="px-lg-5 pt-0">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" novalidate>
      <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
      <div class="md-form mb-2">
        <label for="materialLoginFormEmail" class="label">E-mail</label>
        <input type="text" class="form-control"
               formControlName="emailAddress"
               id="emailAddress" mdbInput />
      </div>
      <button class="btn btn-primary" type="submit">
        Submit
      </button>
    </form>
  </mdb-card-body>
</mdb-card>

