import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Category } from '../../model/category';
import { AdminService } from '../../services/Admin/admin.service';
import { CoreService } from '../../services/core/core.service';

@Component({
  selector: 'app-faqentry',
  templateUrl: './faqentry.component.html',
  styleUrls: ['./faqentry.component.css']
})
export class FaqentryComponent implements OnInit {
  public allCategory: Category[];
  public categoryId: number;
  private fileData: FormData = new FormData()
  successMsg: string | undefined;
  errMessage: string | undefined;
  faqForm: UntypedFormGroup;

  constructor(private coreService: CoreService, private fb: UntypedFormBuilder, private adminService: AdminService ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getCategory();
  }
  createForm() {
    this.faqForm = this.fb.group({
      question: new UntypedFormControl('', [Validators.required]),
      answer: new UntypedFormControl('', [Validators.required]),
      active: new UntypedFormControl('', [Validators.required]),
      categoryId: Number,
    });
  }
  getCategory() {
    this.coreService.getAllCategory()
      .subscribe((data: Category[]) => {
        this.allCategory = data;
      });
  }

  save() {
    //this.faqForm.value.countryName = this.allCountry.filter(x => x.countryCode == this.usefulLinkForm.value.countryCode)[0].countryName;
    let credentials1 = JSON.stringify(this.faqForm.value);
    this.fileData.append('data', credentials1);
    let credentials = this.fileData;

    this.adminService.faqSave(credentials).subscribe(response => {

      let info = (<any>response).success;
      this.successMsg = "Faq added successfully";

    }, err => {
      this.errMessage = "Error occured";
    });
  }
}
