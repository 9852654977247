<br /><br />
<div class="card border-0 p-0 w-100">
  <div class="card-header text-center card-header-bgk"><h1 class="regblock"> <i class="fa fa-stop">&nbsp;Stop Service</i></h1></div>
  <div class="card-body">
    <form [formGroup]="stopServiceForm" (ngSubmit)="saveStopService()" novalidate>
      <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>

      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">First Name </label>
        <input class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-5"
               formControlName="firstName" disabled
               id="firstName" />
      </div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Last Name</label>
        <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-5"
               formControlName="lastName" disabled
               id="lastName" />
      </div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Causes</label>
        <ng-select [items]="serviceStopCauses"
                   bindLabel="causeName"
                   bindValue="id"
                   [multiple]="false"
                   placeholder="Select Cause"
                   formControlName="selectedServiceCauseId"
                   [(ngModel)]="selectedServiceCauseId"
                   (change)="getOtherCause()"
                   class="col-xs-12 col-sm-7 col-md-5 col-lg-5 p-0">
        </ng-select>
      </div>
      <div class="form-group form-inline" *ngIf="isOtherCause">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Cause</label>
        <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-5"
               formControlName="otherCause"
               id="otherCause" />
      </div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2 label">Service stop date</label>
        <app-calendar-control (onDate)="getDate($event)"></app-calendar-control>
      </div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Comments(optional)</label>
        <textarea class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-5"
                  formControlName="comments" cols="5" rows="5" placeholder="Comments"
                  id="comments"></textarea>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-success">Save</button>
      </div>
    </form>
  </div>
</div>




