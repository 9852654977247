import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselComponent } from './components/carousel/Carousel.component';
import { ReactiveFormsModule } from "@angular/forms";
import { Authguard } from '././services/authguard/authguard.service';

import { MatListModule } from '@angular/material/list';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LogOutApiComponent } from './components/log-out-api/log-out-api.component';
import { MatTableModule } from '@angular/material/table';
import { ServceproviderComponent } from './components/servceprovider/servceprovider.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ServiceprovidercontactComponent } from './components/serviceprovidercontact/serviceprovidercontact.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { PortalMessageComponent } from './components/Shared/portal-message/portal-message.component';
import { SearchComponent } from './components/search/search.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MDBBootstrapModule } from 'angular-bootstrap-md'

import { SigninValidationService } from './services/SigninValidation/sign-validation.service';

import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input'
import {MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { PasswordresetComponent } from './components/passwordreset/passwordreset.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { MatRadioModule } from '@angular/material/radio';
import { ConversationNavComponent } from './components/conversation-nav/conversation-nav.component';
import { EmailDetailsComponent } from './components/email-details/email-details.component';
import { ComposeEmailComponent } from './components/compose-email/compose-email.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { StartupComponent } from './components/startup/startup.component';

import { Authinterceptor } from '../api-authorization/authinterceptor';
import { HiremeComponent } from './components/hireme/hireme.component';

import {  formatData } from './custompipes/format-data';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { RatingComponent } from './components/rating/rating.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarControlComponent } from './components/calendar-control/calendar-control.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { StopserviceComponent } from './components/stopservice/stopservice.component';
import { HomepageInfoComponent } from './components/homepage-info/homepage-info.component';
import { PersonalcardComponent } from './components/personalcard/personalcard.component';

import { NewRorOrColPipe } from './pipes/new-ror-or-col.pipe';
import { DataFilter } from './pipes/datafilter.pipe';
import { CarouselByUserComponent } from './components/carousel-by-user/carousel-by-user.component';
import { FacebookModule } from 'ngx-facebook';
import { FblivechatComponent } from './components/fblivechat/fblivechat.component';
import { FooterComponent } from './components/footer/footer.component';
import { CookieService } from 'ngx-cookie-service';
import { PaymentComponent } from './components/payment/payment.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FileSizePipePipe } from './pipes/file-size-pipe.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ConvertToBase64Pipe } from './pipes/convert-to-base64.pipe';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { NgxSpinnerModule } from "ngx-spinner";
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ModalComponent } from './components/modal/modal.component';
import { NgxTinymceModule } from 'ngx-tinymce';

import { EditorModule } from "@tinymce/tinymce-angular";
import { UsefullinkComponent } from './components/usefullink/usefullink.component';
import { CarriersiteComponent } from './components/Shared/carriersite/carriersite.component';
import { CarrierlinkComponent } from './components/carrierlink/carrierlink.component';
import { FaqentryComponent } from './components/faqentry/faqentry.component';

import { JobsbycountryComponent } from './components/jobsbycountry/jobsbycountry.component';
import { SpinnerloaderComponent } from './components/spinnerloader/spinnerloader.component';
import { MatDialogModule } from '@angular/material/dialog';

import {YouTubePlayerModule} from '@angular/youtube-player'
import { VideoComponent } from './components/video/video.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SitemappingComponent } from './components/Sitemapping/Sitemapping.component';

import { ProfessionalServiceComponent } from './components/whattodo/professionalservice.component';
import { AppRoutingModule } from './app-routing.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        CarouselComponent,
     
        StartupComponent,
   
        LogOutApiComponent,
        ServceproviderComponent,
        ServiceprovidercontactComponent,
 
        ConfirmEmailComponent,
        PortalMessageComponent,
        SearchComponent,
 
        PasswordresetComponent,
        ForgotpasswordComponent,
        ConversationNavComponent,
        EmailDetailsComponent,
        ComposeEmailComponent,
 
        HiremeComponent,
  
        ReviewsComponent,
        formatData,
        RatingComponent,
        CalendarControlComponent,
        StopserviceComponent,
        HomepageInfoComponent,
        PersonalcardComponent,
        DataFilter,
        NewRorOrColPipe,
        CarouselByUserComponent,
        FblivechatComponent,
        FooterComponent,
        PaymentComponent,
        FileSizePipePipe,
        ConvertToBase64Pipe,
        SpinnerComponent,
        ModalComponent,
      
        UsefullinkComponent,
        CarriersiteComponent,
        CarrierlinkComponent,
        FaqentryComponent,
       
        JobsbycountryComponent,
        SpinnerloaderComponent,
        VideoComponent,
        VideoPlayerComponent,
        SitemappingComponent,
        ProfessionalServiceComponent
  ],
  //exports: [PortalMessageComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
       
        NgbModule,
        ReactiveFormsModule,
        MatTableModule,
        NgSelectModule,
        NoopAnimationsModule,
        NgbModalModule,
        MatCardModule,
        MatCheckboxModule,
        MatToolbarModule,
  
        MatFormFieldModule,
        MatButtonModule,
        MDBBootstrapModule.forRoot(),
      
        NoopAnimationsModule,
        MatInputModule,
        MatSidenavModule,
        MatPaginatorModule,
        MatSelectModule,
        MatListModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        EditorModule,
        MatExpansionModule,
        MatIconModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule,
        AppRoutingModule,
       
        YouTubePlayerModule,
        NgxTinymceModule.forRoot({
            baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/5.3.2/'
        }),
        NgxMaskModule.forRoot(maskConfig),
        FacebookModule.forRoot(),
        ImageCropperModule,
        ShowHidePasswordModule,
        NgxSpinnerModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule.forRoot([
            { path: '', component: HomeComponent, pathMatch: 'full' },
            { path: 'dynamic', component: CarouselComponent },       
            { path: 'join', component: StartupComponent, canActivate: [SigninValidationService] },           
            { path: 'log-out', component: LogOutApiComponent },
            { path: 'servieprovider', component: ServceproviderComponent, canActivate: [Authguard] },
            { path: 'serviceprofile', component: ServiceprovidercontactComponent, canActivate: [Authguard] },         
            { path: 'confirmEmail', component: ConfirmEmailComponent },
            { path: 'portal-message', component: PortalMessageComponent },
            { path: 'search', component: SearchComponent, canActivate: [Authguard] },         
            { path: 'forgotpassword', component: ForgotpasswordComponent },
            { path: 'resetpassword', component: PasswordresetComponent },
            { path: 'conversation', component: ConversationNavComponent, canActivate: [Authguard] },
            { path: 'maildetails', component: EmailDetailsComponent, canActivate: [Authguard] },
            { path: 'emailcompose', component: ComposeEmailComponent, canActivate: [Authguard] },         
            { path: 'hireme', component: HiremeComponent, canActivate: [Authguard] },         
            { path: 'reviews', component: ReviewsComponent, canActivate: [Authguard] },
            { path: 'rating', component: RatingComponent, canActivate: [Authguard] },
            { path: "calendar", component: CalendarControlComponent },
            { path: "stopservice", component: StopserviceComponent, canActivate: [Authguard] },
            { path: "homepageinfo", component: HomepageInfoComponent },
            { path: "personal-card", component: PersonalcardComponent },
            { path: "dynamic-carousel", component: CarouselByUserComponent },
            { path: 'footer', component: FooterComponent },
            { path: 'payment', component: PaymentComponent, canActivate: [Authguard] },          
            { path: 'link', component: UsefullinkComponent, canActivate: [Authguard] },
            { path: 'carrier', component: CarriersiteComponent },
            { path: 'carrier-link', component: CarrierlinkComponent },
            { path: 'addfaq', component: FaqentryComponent, canActivate: [Authguard] },           
            { path: 'jobsbycountry', component: JobsbycountryComponent },
            { path: 'loader', component: SpinnerloaderComponent },
            { path: 'videop', component: VideoComponent },
            { path: 'video-player', component: VideoPlayerComponent },
            { path: 'page-mapping', component: SitemappingComponent },
            { path: 'whattodo', component: ProfessionalServiceComponent }
        ])
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: Authinterceptor, multi: true },
        CookieService
       
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
