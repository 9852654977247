import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Data } from '@angular/router';

@Component({
  selector: 'app-portal-message',
  templateUrl: './portal-message.component.html',
  styleUrls: ['./portal-message.component.css']
})
export class PortalMessageComponent {

  constructor() { }

  ngOnInit() {
  }
  @Input()
  successMsg: string;

  @Input()
  errMsg: string;

  @Output()
  onButtonClick = new EventEmitter<Data>();
}
