import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sitemap',
  templateUrl: './Sitemapping.component.html',
  styleUrls: ['./Sitemapping.component.css']
})
export class SitemappingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
