import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FeatureLectureModel } from '../../model/feature-lecture-model';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { SetBrowserService } from '../../services/set-browser.service';
import { VideoplayerService } from '../../services/videoplayer.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {


  constructor(private setbrowserService: SetBrowserService,
    private portalnInfo: PortalninformationService,
    private activatedroute: ActivatedRoute,
    private videoPlayer: VideoplayerService,
    private title: Title) { }

  public featureLectures!: FeatureLectureModel[];
  ngOnInit(): void {
    this.getAllFeatureLectures();
  }
  getAllFeatureLectures() {
    this.videoPlayer.getAllFeatureLectures().subscribe((result: any) => {
      this.featureLectures = result;

    });
  }
}
