import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private _loading: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  public readonly loading = this._loading.asObservable();
  constructor() { }
  show() {
    this._loading.next(true);
  }
  hide() {
    this._loading.next(false);
  }
}
