<br />
<br />
<div class="sidebar  bg-light">
  <div *ngFor="let evnt of events">
    <a [routerLink]="evnt.routing"><span class="sidebarfont" style="color: #813391">&nbsp;{{evnt.eventsName}}</span></a>
  </div>
</div>
<div class="content">
  <!--<div class="row mb-1">
    <div class="col-sm-12 col-md-12 col-lg-12">-->
  <!--<h2>Welcome to Finding Professional in {{specialLookup?.cityName}}</h2>-->
  <!--<p class="title mb-2">Finding Professional help you to find your desired service for all categories like tutor, tuition,etc around the world with no cost.To get in details click <a href="#" [routerLink]="['/join']">here</a> to subscribe.</p>-->
  <!--<p class="title mb-2" *ngIf="noSeviceMessage" [innerHTML]="noSeviceMessage.description"></p>-->
  <!--</div>
  </div>
  <hr />-->
  <div class="row" *ngIf="!isAuthenticated">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <app-carousel-by-user></app-carousel-by-user>
      <app-jobsbycountry></app-jobsbycountry>
      <app-fblivechat></app-fblivechat>
    </div>

    <div class="col-xs-9 col-sm-9 col-md-8 col-lg-8">
      <app-homepage-info  [topserviceProviders]="topserviceProviders" [totRow]="totRow" ></app-homepage-info>
      <app-professionalservice *ngIf="topserviceProviders?.length==0"></app-professionalservice>
      <!--<p class="title" *ngIf="noSeviceMessage">{{noSeviceMessage.description}}&nbsp;<a href="#" [routerLink]="['/contact-us']">{{noSeviceMessage.actionTitle}}</a></p>-->
      <app-fblivechat></app-fblivechat>
     
    </div>
  </div>

  <div class="row" *ngIf="isAuthenticated">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <app-jobsbycountry></app-jobsbycountry>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-8 col-lg-8">
      <!--<p class="title" *ngIf="noSeviceMessage" [innerHTML]="noSeviceMessage.description"></p>-->
      <app-professionalservice *ngIf="topserviceProviders?.length==0"></app-professionalservice>
      <app-homepage-info  [topserviceProviders]="topserviceProviders" [totRow]="totRow" ></app-homepage-info>
    </div>
  </div>
  <div>
    <app-video-player></app-video-player>
  </div>
</div>


