
<div class="card w-100 border-0">
  <table class="table-responsive">
    <tr>
      <td class="video-title">
        <h1>{{video?.lectureTitle}}</h1>
      </td>
   </tr>
    <tr>
      <td>
        <h1 class="fullname">
          <b>Lectured by </b>  {{video?.fullName}} on <b>{{video?.subjectName}}</b>
        </h1>
        </td>
       </tr>
         <tr>
            <td>
              <youtube-player [videoId]="video?.videoId"
                              [height]="600"
                              [width]="1080"
                              [startSeconds]="0">
              </youtube-player>

            </td>
          </tr>
      
    
    </table>
</div>
 
