  <div class="row">
    <div class="col-md-12 col-sm-12 col-lx-12 py-3" *ngIf="carousels?.carousels?.length>0">
      <p class="job-title">Available top opportunity({{carousels?.total}})</p>
        <ngb-carousel interval="10000" [keyboard]="false" [showNavigationIndicators]="true"
                      [pauseOnHover]="true" [showNavigationArrows]="true">
          <ng-template ngbSlide *ngFor="let event of carousels?.carousels" >
            <img [src]="event.img" class="w-100" alt="Random Slid" style="min-height:220px; max-height: 260px;background-color: #9F4CEC" />
            <div class="carousel-caption text"> 
              <p>
                <a [routerLink]="['/serviceprofile']" [queryParams]="{userId:event?.id}" class="text">
                  {{event.fullName}}
                </a>
              </p>
              <p *ngIf="event.label"><b>{{event.label}}:</b> {{event.educattion}}</p>
              <p><b>Subjects:</b> {{event.subjects}}</p>
              <p *ngIf="event.city">{{event.city}}, {{event.districtName}}</p>
            </div>

          </ng-template>
        </ngb-carousel>
      </div>
  </div>
