import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {
  private userName: string;
  private token: string;
  public successMsg: string;
  public errMessage: string;
  constructor(private router: Router, private loginService: LoginService, private Activatedroute: ActivatedRoute, private title: Title) {
    
    this.userName = this.Activatedroute.snapshot.queryParamMap.get('userId') || null;
    this.token = this.Activatedroute.snapshot.queryParamMap.get('code') || null;
  }

  ngOnInit() {
    this.title.setTitle("Confirm Identity-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.confirmEmail();
  }

  confirmEmail() {
    
    this.token = this.token.replace(/\+/gi, '%2B');
    this.loginService.confirmEmail(this.userName, this.token)
      .subscribe((data: any) => {
        this.successMsg = data.msg;
      },
        err => {
          var rr = err;
        });
  }
}
