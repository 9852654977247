
<div class="footer_top">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-md-6 col-lg-4">
        <div class="footer_widget">
          <h3 class="footer_title">
            Follow Us
          </h3>
          <div class="socail_links">
            <ul>
              <li>
                <a href="https://www.facebook.com/Finding-Professional-101498921669063" target="_blank">
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fab fa-youtube"></i>
                </a>
              </li>

              <li>
                <a href="https://www.linkedin.com/company/findingprofessional/about/?viewAsMember=true">
                  <i class="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-3">
        <div class="footer_widget">
          <h3 class="footer_title">
            Resourches
          </h3>
          <ul class="no-bullets">
            <li><a href="#" class="linkcolor">Terms and Condition</a></li>
            <li><a href="#" class="linkcolor">What we do</a></li>
            <li>
              <a href="#" class="linkcolor" [routerLink]="['/aboutus']">
                About
              </a>

            </li>
            <li>
              <a href="#" class="linkcolor" [routerLink]="['/contact-us']">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-2 col-md-6 col-lg-2">
        <div class="footer_widget">
          <h3 class="footer_title">
            Get in touch
          </h3>
          <p>
            <a href="tel:+1 201 365 7817" class="linkcolor">Call Us</a><br />
            <a href="mailto:sahaku@gmail.com?subject=Help needed!" class="linkcolor">Contact Us</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copy-right_text">
  <div class="container">
    <div class="footer_border"></div>
    <div class="row">
      <div class="col-xl-12">
        <p class="copy_right text-center">
          Copyright &copy;{{fullYear}} by <a href="#" target="_blank" class="linkcolor">Finding Professional</a>
        </p>
      </div>
    </div>
  </div>
</div>
