import {
  Component, OnInit, ChangeDetectionStrategy,  ViewChild,  TemplateRef, EventEmitter, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
/*import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material';*/

@Component({
  selector: 'app-calendar-control',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-control.component.html',
  styleUrls: ['./calendar-control.component.css']
})
export class CalendarControlComponent implements OnInit {
  constructor(private modal: NgbModal) { }
  
  events: string[] = [];
  @Output() onDate = new EventEmitter<Date>();
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    //this.events.push(`${type}: ${event.value}`);
    this.onDate.emit(event.value);
  }

  ngOnInit() {
  }

}
