import { Component, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
//import { MatSidenavContainer } from '@angular/material';
import { EmailService } from '../../services/email/email.service';
import { InOutSentItem } from '../../model/in-out-sent-item';
import { MailType } from '../../constants/api-portal-constant';
import { Filters } from '../../implementations/filters';
import { CommunicationFilter } from '../../implementations/communication-filter';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-conversation-nav',
  templateUrl: './conversation-nav.component.html',
  styleUrls: ['./conversation-nav.component.css']
})
export class ConversationNavComponent implements  OnDestroy, OnInit {
  //@ViewChild(MatSidenavContainer, { static: false }) sidenavContainer: MatSidenavContainer;
  mobileQuery: MediaQueryList;
  public inboxItems: InOutSentItem[];
  public sentItems: InOutSentItem[];
  public outboxItems: InOutSentItem[];
  public ELEMENT_DATA: InOutSentItem[];
  public filter: CommunicationFilter = new CommunicationFilter();
  public displayedColumns: string[] = ['mailToName', 'subject'];
  public pageIndex: number = 0;
  public skip: number = 0;
  //pageNumber: number;
  pageSize: number;
  public mailBoxType: string;
  public length: number = 100;
  public mailBox: string;
  public mailboxItem: string ;
  public mailDetails: string;
  public messageId: number;
  public mailToUserId: any;
  public fullName: string;
  public isPrepolutedFullName: string = "N";
  //ngAfterViewInit() {
  //  //this.sidenavContainer.scrollable.elementScrolled().subscribe(() => /* react to scrolling */);
  //}
  public _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    public emailService: EmailService, public router: Router, private Activatedroute: ActivatedRoute, private title: Title) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.mailToUserId = this.Activatedroute.snapshot.queryParamMap.get('userId') || 0;
    this.fullName = this.Activatedroute.snapshot.queryParamMap.get('fullName') || undefined;
    //debugger
    if (this.fullName != undefined) {
      this.isPrepolutedFullName = "Y";
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngOnInit() {
    this.title.setTitle("Conversation-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.getInboxBox("I");
  }
  showFiller = false;
  getMailBox(mailBoxType: string) {
    
    this.mailboxItem = mailBoxType;
    this.mailDetails = null;
    this.fullName = undefined;
    this.isPrepolutedFullName = "N";

    if (this.fullName == undefined || this.isPrepolutedFullName == "N") {
      this.mailBoxType = mailBoxType;
      this.fullName = null;
      if (mailBoxType == MailType.Inbox) {
        this.mailBox = " Inbox";
      }
      if (mailBoxType == MailType.OutBox) {
        this.mailBox = " Outbox";
      }
      if (mailBoxType == MailType.SentItem) {
        this.mailBox = " Sent Items";
      }
      this.filter.mailBoxType = mailBoxType;
      this.emailService.getMailBox(this.filter).subscribe(response => {

        this.ELEMENT_DATA = response.mailBox
        //console.log(this.ELEMENT_DATA);
      }, err => {
        //debugger
      });
    }
   
  }
  getInboxBox(mailBoxType: string) {
  
    this.mailboxItem = mailBoxType;
    this.mailDetails = null;
    if (this.fullName == undefined || this.isPrepolutedFullName == "N") {
      this.mailBoxType = mailBoxType;
      this.fullName = null;
      if (mailBoxType == MailType.Inbox) {
        this.mailBox = " Inbox";
      }
      if (mailBoxType == MailType.OutBox) {
        this.mailBox = " Outbox";
      }
      if (mailBoxType == MailType.SentItem) {
        this.mailBox = " Sent Items";
      }
      this.filter.mailBoxType = mailBoxType;
      this.emailService.getMailBox(this.filter).subscribe(response => {

        this.ELEMENT_DATA = response.mailBox
        //console.log(this.ELEMENT_DATA);
      }, err => {
        //debugger
      });
    }

  }
  changePage(event) {

    this.filter.pageNumber = event.pageIndex;
    this.filter.pageSize = event.pageSize;
    this.getMailBox(this.mailBoxType);
    if (this.ELEMENT_DATA != undefined && this.length > this.ELEMENT_DATA.length) {
      if (this.pageIndex < event.pageIndex) {
        this.skip = this.skip + this.pageSize;
      }
    }
  }
  composeEmail(userId: number) {
   // debugger;
    this.fullName = 'Full Name';
    this.isPrepolutedFullName = "N";
    this.mailDetails = null;
    this.mailboxItem = null;
  }
  openMail(messageId: number, mailboxType: string) {
   // this.mailboxItem = undefined;  
    this.mailDetails = mailboxType;
    this.mailBoxType = mailboxType;
    this.messageId = messageId;
    //this.mailDetails = null;
    this.mailboxItem = null;
    this.fullName = null;
    this.isPrepolutedFullName = "N";
  }
}
