import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Namelookup } from '../../model/namelookup';
import { EmailService } from '../../services/email/email.service';
import { startWith, map } from 'rxjs/operators';
//import { deepEqual } from 'assert';
import { ComposeMail } from '../../model/compose-mail';
//import { noUndefined } from '@angular/compiler/src/util';
import { EmailTemplate } from '../../constants/api-portal-constant';
import { Title } from '@angular/platform-browser';
import { IpServiceService } from '../../services/ip-service.service';

export const _filter = (opt: Namelookup[], value: string): Namelookup[] => {
  //debugger
  const filterValue = value.toLowerCase();
  var item = opt.filter(item => item.mailToName.toLowerCase().indexOf(filterValue) === 0);
  //debugger;
  return item;
};

@Component({
  selector: 'app-compose-email',
  templateUrl: './compose-email.component.html',
  styleUrls: ['./compose-email.component.css']
})
export class ComposeEmailComponent implements OnInit {
  public composeEmailForm: UntypedFormGroup;
  public nameLookup: Namelookup[];
  public tinyMceConfig: any;
  //public stateGroupOptions: Observable<Namelookup[]>;
  public errMessage: string;
  public successMsg: string;
  public ipAddress: string;
  constructor(public fb: UntypedFormBuilder, public router: Router, public emailService: EmailService,
    private title: Title,
    private ipService: IpServiceService ) {
    
  }

  @Input()
  public fullName: string;

  @Input()
  public mailToUserId: number;

  @Input()
  public isPrepoluatedName: string;

  ngOnInit() {
    this.getIP();
    //this.initTinyMce();
    this.title.setTitle("Email-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.createForm();
    //debugger
    if (this.isPrepoluatedName != undefined && this.isPrepoluatedName == "Y") {
      //this.fullName = this.mailTofullName
      this.composeEmailForm.get("mailToNameQ").setValue(this.fullName);
    }
  }

  createForm() {
    this.composeEmailForm = this.fb.group({
      mailToName: [''],
      subject: ['', Validators.required],
      body: ['', Validators.required],
      mailToNameQ: [''],
      mailToUserId: Number,
      emailTemplateCode: '',
      ipAddress: ''
    });
  }

  sendEmail() {
    var composeEmail: ComposeMail;
    //debugger
    if (this.composeEmailForm.value.mailToNameQ != undefined && this.composeEmailForm.value.mailToNameQ != "") {
      this.fullName = this.composeEmailForm.value.mailToNameQ;
    }
    else {
      this.fullName = this.composeEmailForm.value.mailToName.mailToName;
      this.mailToUserId = this.composeEmailForm.value.mailToName.mailToUserId;
    }
    this.composeEmailForm.value.ipAddress = this.ipAddress;
    this.composeEmailForm.value.mailToName = this.fullName;
    this.composeEmailForm.value.mailToUserId = this.mailToUserId;
    this.composeEmailForm.value.emailTemplateCode = EmailTemplate.GeneralEmail;
    let credentials1 = JSON.stringify(this.composeEmailForm.value);
  
    var fileData: FormData = new FormData();
    fileData.append('data', credentials1);
    let credentials = fileData;
    if (credentials != undefined) {
      this.emailService.sendEmail(credentials).subscribe(response => {
        let info = (<any>response).success;
        if (info != undefined) {
          this.successMsg = info;
          this.errMessage = null;
        }
        else {
          this.errMessage = response.errMsg;
          this.successMsg = null;
        }
      }, err => {
       
      });
    }
    
  }
  _filterGroup() {
    //debugger
    if (this.composeEmailForm.get('mailToName') != undefined) {
      var value = this.composeEmailForm.get('mailToName').value;
      var stateGroups: Namelookup[];
      if (value) {
        this.emailService.getEmailAddress(value).subscribe((response: Namelookup[]) => {
          stateGroups = response;
          this.nameLookup = stateGroups.filter(x => x.mailToName.includes(value));
        }, err => {

        });

      }
    }
   return stateGroups;
  }

  displayFn(nameLookup: Namelookup): string {
    this.mailToUserId = nameLookup.mailToUserId;
    return nameLookup.mailToName;
  }
  initTinyMce() {
    this.tinyMceConfig = {
      branding: false,
      height: 250,    
      toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | numlist  bullist'
      
    };
  }
  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    }, _error => {
      this.errMessage = _error.msg;
    });
  }
}
