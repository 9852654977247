import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Browsers, PortalRole } from '../../constants/api-portal-constant';
@Injectable({
  providedIn: 'root'
})
export class LocalCookieService {

  constructor(private cookieService: CookieService) { }

  setCookie(userName: string) {
    const dateNow = new Date();
    dateNow.setDate(dateNow.getDate() + 15);
    this.cookieService.set(userName, 'true', dateNow);
  }

  deleteCookie(userName: string) {
    this.cookieService.delete(userName);
  }

  getCookie(userName: string): boolean {
    return this.cookieService.check(userName);
  }
  setRole(roleName: string) {
    if (this.cookieService.check(PortalRole.Role)) {
      this.cookieService.delete(PortalRole.Role);
    }

    const dateNow = new Date();
    dateNow.setMinutes(dateNow.getDate() + 60);
    this.cookieService.set(PortalRole.Role, roleName, dateNow);
  }

  getRole() {
    return this.cookieService.get(PortalRole.Role);
  }
  deleteRole() {
    if (this.cookieService.check(PortalRole.Role)) {
      this.cookieService.delete(PortalRole.Role);
    }
  }
}
