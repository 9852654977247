<mdb-card class="cardwith card-css border-0">
  <form [formGroup]="composeEmailForm" (ngSubmit)="sendEmail()" novalidate>
    <mdb-card-body class="px-lg-5 pt-0 border-0">
      <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
      <div class="row mb-3 form-group">
        <div class="col-xs-8 col-sm-8 col-md-1 col-lg-1">
          <label for="labelTo" class="label">
            To:
          </label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-10 col-lg-10">
          <mat-form-field class="matinputwidth">
            <div id="txtEmail" *ngIf="isPrepoluatedName=='N'">
              <input type="text" formControlName="mailToName" name="mailToName" id="mailToName" [matAutocomplete]="autoGroup"
                     placeholder="Recipient" matInput (input)="_filterGroup()">
              <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let name of nameLookup" [value]="name">
                  {{name.mailToName}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div *ngIf="isPrepoluatedName=='Y'">
              <input type="text" formControlName="mailToNameQ" name="mailToNameQ" id="mailToNameQ"
                     placeholder="Recipient" matInput readonly>
            </div>

          </mat-form-field>

        </div>
      </div>

      <div class="row mb-3 form-group">
        <div class="col-xs-8 col-sm-8 col-md-1 col-lg-1">
          <label for="labelSubject" class="label">Subject</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-10 col-lg-10">
          <mat-form-field class="matinputwidth">
            <input type="text" formControlName="subject" id="subject" placeholder="Subject" matInput />
          </mat-form-field>

        </div>
      </div>

      <div class="row form-group">
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
          <label for="labelBody" class="label">Note</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
          <!--<tinymce formControlName="body"
                   id="body" [config]="tinyMceConfig"></tinymce>-->

          <editor apiKey="u370vgj6e4u0hrmc7sz9zrjm0wljblju3qbvjfmxvl81ndxs"
                  formControlName="body" 
                  id="body"
                  [init]="{
               height: 400,
               menubar: false,
               branding:false,
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount'
                     ],
                     toolbar:
                       'undo redo | formatselect | bold italic backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help'
   }"></editor>

        </div>
      </div>
      <div class="row form-group">
        <div class="col-xs-8 col-sm-8 col-md-1 col-lg-1">
          <input type="submit" id="btnSend" name="btnSend" value="Send" class="btn btn-primary" />
        </div>
      </div>
    </mdb-card-body>
  </form>

</mdb-card>
