<div class="row">
  <div class="col-md-12 col-sm-12 col-lx-12 py-3" *ngIf="jobsByCountry?.jobSeekerList?.length>0">
    <p class="job-title">Available top opportunity by country</p>
    <ngb-carousel interval="10000" [keyboard]="false" [showNavigationIndicators]="true" [pauseOnHover]="true" [showNavigationArrows]="true">
      <ng-template ngbSlide *ngFor="let event of jobsByCountry?.jobSeekerList">
        <img [src]="event.img" class="w-100" alt="Random Slid" style="min-height:220px; max-height: 260px;background-color: #9F4CEC" />
        <div class="carousel-caption text">        
          <p>{{event.countryName}}</p> 
          <p><b>Available Opportunity:</b> {{event.totalOpportunity}}</p>        
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row">
  <div class="col-md-12 col-sm-12 col-lx-12 py-3" *ngIf="jobsByCountry?.providerJobList?.length>0">
    <p class="job-title">Available top job seekers by country</p>
    <ngb-carousel interval="10000" [keyboard]="false" [showNavigationIndicators]="true"
                  [pauseOnHover]="true" [showNavigationArrows]="true">
      <ng-template ngbSlide *ngFor="let event of jobsByCountry?.providerJobList">
        <img [src]="event.img" class="w-100" alt="Random Slid" style="min-height:220px; max-height: 260px;background-color: #9F4CEC" />
        <div class="carousel-caption text">
          <p>{{event.countryName}}</p>
          <p><b>Available Opportunity:</b> {{event.totalOpportunity}}</p>
       </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
