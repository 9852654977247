import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from 'rxjs';
import { AuthToken } from '../../constants/api-portal-constant';

@Injectable({
  providedIn: 'root'
})
export class Authguard implements CanActivate {
  private jwtHelper: JwtHelperService
  constructor(private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }
  canActivate() {
    var token = localStorage.getItem(AuthToken.AuthTokenKey);

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
      this.router.navigate(["loginapi"]);
    return false;
  }
}
