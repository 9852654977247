import { IFilters } from "../model/ifilters";

export class Filters implements IFilters {
  divisionId: number;
  districtId: number;
  thanaId: number;
  category: number;
  postalCode: string;
  city: string;
  address: string;
  education: number[];
  email: string;
  phone: string;
  educationInstitute: number[];
  pageNumber: number;
  pageSize: number;
  mailBoxType: string;
  subjects: number[];
  ip: string;
  constructor() {
    this.postalCode = null;
    this.city = null;
    this.address = null;
    this.education = null;
    this.email = null;
    this.phone = null;
    this.educationInstitute = null;
    this.pageSize = null;
    this.pageNumber = null;
    this.mailBoxType = null;
    this.divisionId = null;
    this.districtId = null;
    this.thanaId = null;
    this.subjects = null;
    this.ip = null;
  }

}
