import { Component, OnInit } from '@angular/core';
import { WhatToDoModel } from '../../model/what-to-do-model';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';

@Component({
  selector: 'app-professionalservice',
  templateUrl: './professionalservice.component.html',
  styleUrls: ['./professionalservice.component.css']
})
export class ProfessionalServiceComponent implements OnInit {
  public whatToDoModel: WhatToDoModel[];
  title!: string;
  constructor(private portalninformationService:PortalninformationService) { }
  
  ngOnInit(): void {
    this.getHomePageEmptySpace();
  }

  getHomePageEmptySpace() {
    this.portalninformationService.getHomePageEmptySpace().subscribe((data: WhatToDoModel[]) => {
      this.whatToDoModel = data;
      this.title = this.whatToDoModel?.length > 0 ? this.whatToDoModel[0].title : null;
    });
  }
}
