import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToBase64'
})
export class ConvertToBase64Pipe implements PipeTransform {

  transform(value: string): any {
    return atob(value);
  }

}
