import { Pipe, PipeTransform } from '@angular/core';
import { Columns } from '../constants/api-portal-constant';
import { Toppers } from '../model/toppers';

@Pipe({
  name: 'datafilter'
})
export class DataFilter implements PipeTransform {

  transform(col: number, data: Toppers[], indx: number): any {
  // debugger
    var endIndex: number = 0;
    endIndex = indx * col;
    var startIndx = ((indx - 1) * col) + 1;
    
    var filterData = data.filter(x => x.recNo >= startIndx && x.recNo <= endIndex);
    return filterData;
  }
}
