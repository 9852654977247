import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../helper/must-match.validator';
import { PasswordResetModel } from '../../model/password-reset-model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  public userName: string;
  public token: string;
  public successMsg: string;
  public errMessage: string;
  public forgotPasswordForm: UntypedFormGroup;
  public passpordReset: PasswordResetModel;

  constructor(public fb: UntypedFormBuilder, public loginService: LoginService, public Activatedroute: ActivatedRoute, private title: Title) {

   
  }

  ngOnInit() {
    this.createForm();
    this.title.setTitle("Forgot Password-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
  }

  forgotPassword() {
      
    let passwordResetJson = JSON.stringify(this.forgotPasswordForm.value);
    this.loginService.forgotPassword(passwordResetJson)
      .subscribe((data: any) => {
       
        let info = (<any>data).success;
        if (info != undefined) {         
          this.successMsg = info;
        }
        else {
          this.errMessage = data.errMsg;
          this.successMsg = null;
        }
      },
        err => {
         

          var rr = err;
        });
  }

  createForm() {
    this.forgotPasswordForm = this.fb.group({
      emailAddress: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(80)])      
    });
  }
}
