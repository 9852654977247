import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthToken } from "../app/constants/api-portal-constant";
import { Observable } from "rxjs";
import { SpinnerService } from "../app/services/spinner/spinner.service";
import { finalize } from "rxjs/operators";

@Injectable()
export class Authinterceptor implements HttpInterceptor {
  constructor(private loader: SpinnerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const idToken = localStorage.getItem(AuthToken.AuthTokenKey);
    this.loader.show();
    if (!!idToken && this.isSameOriginUrl(req)) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + idToken)
      });
      return next.handle(cloned).pipe(
        finalize(() => {
          this.loader.hide();
        })
      );
    }
    else {
      return next.handle(req).pipe(
        finalize(() => {
          this.loader.hide();
        })
      );
    }
  }
  private isSameOriginUrl(req: any) {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

   
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    
    return false;
  }
}
