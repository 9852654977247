import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login/login.service';
import { Observable } from 'rxjs';
import { User } from 'oidc-client';
import { AuthenticatedUser } from '../model/authenticated-user';
import { Router } from '@angular/router';
import { ServieupdateService } from '../services/servieupdate.service';
import { AuthToken, PortalRole } from '../constants/api-portal-constant';
import { MenuByCategoryModel } from '../model/menu-by-category-model';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent  implements OnInit {

  private invalidLogin: boolean;
  public user: AuthenticatedUser;
  public adminUser: string = PortalRole.Admin;
  public isPaymentEnable: boolean = false;
  constructor(private loginService: LoginService, private router: Router,
    private servieupdateService: ServieupdateService) {
      //super();
  }

  ngOnInit(): void {
    this.servieupdateService.newAuthUser.subscribe(user => this.user = user);
    this.isAuthenticatedUser();
  }

  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.isAuthenticatedUser();
  }

  isAuthenticatedUser() {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != undefined) {
     // debugger;
      if (this.user == null) {
        this.loginService.getUser().subscribe(response => {

          if (response != null) {

            this.user = this.loginService.assignUser(response.loginModel, true);
          }
        }, _error => {
          this.invalidLogin = true;
        });
      }
      
    }
  }
}
