import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { HttpStatus, AuthToken } from '../../constants/api-portal-constant';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { Router } from '@angular/router';
import { CarouselModel } from '../../model/carousel-model';
import { IpServiceService } from '../../services/ip-service.service';
import { CarouselResponse } from '../../model/carousel-response';

@Component({
  selector: 'app-carousel-by-user',
  templateUrl: './carousel-by-user.component.html',
  styleUrls: ['./carousel-by-user.component.css'],
  //encapsulation: ViewEncapsulation.None
})
export class CarouselByUserComponent implements OnInit {
  public carousels: CarouselResponse;
  constructor(private coreService: CoreService,
    private providersService: ProvidersService,
    private ipService: IpServiceService,
    private router: Router) {

  }
  event_list: any = [];

  upcoming_events = this.event_list;


  getCarousel(ip: string) {
    this.providersService.getCarousel(ip)
      .subscribe((data: CarouselResponse) => {
        this.carousels = data;
        //debugger
      },
        err => {
          if (err.status == HttpStatus.NotFound) {
            localStorage.removeItem(AuthToken.AuthTokenKey);
            this.router.navigate(["/"]);
            const redirectUrl = window.location.origin;
            window.location.replace(redirectUrl);
          }
        });
  }

  ngOnInit(): void {
    this.getIP();
   
  }

  navigate(id) {
    this.router.navigate(["/serviceprofile"], { queryParams: { userId: id } });
  }
  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.getCarousel(res.ip);
    });
  }
}

