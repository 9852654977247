import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthToken } from '../../constants/api-portal-constant';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private baseUrl: string;
  private routePrefix: string = 'api/admin';
  private routePrefixStarpup: string = "api/startup";
  private routePrefixProfile: string = "api/profile";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl;
  }

  getCarrierLink(ipAddress: string) {

    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/${ipAddress}`);
  }

  save(data: any): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/usefullink", data, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }
  }

  getEvents(ipAddress: string):any {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/events/${ipAddress}`);
  }

  faqSave(data: any): any {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.post(`${this.baseUrl}${this.routePrefix}/add-faq`, data, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
    }
  }

  getFaq(categoryId: any): any {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/faq/${categoryId}`);
  }

  getJobsByCountry(): any {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefixStarpup}/job`);
  }
  getCountryNoServiceMessage(ipAddress: string): any {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefixProfile}/cuntry-message/${ipAddress}`);
  }

}

