import { Component, AfterContentChecked, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from './services/spinner/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {
  constructor(public loader: SpinnerService, private cd:ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
    }
   
  title = 'app';
}
