<br /><br />
<div class="card border-0">
  <div class="card-header card-info text-center"  style="background-color:transparent">
    <h1>Subscribe</h1>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="card">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
              <p class="label">I would like to provide service</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
              <input type="button" class="btn btn-primary mb-2" value="Sign up" id="btnServiceProvider" (click)="navigateServiceProvider()" />
            </div>
          </div>
        </div>

      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="card">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
              <p class="label">I need service</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
              <input type="button" class="btn btn-primary mb-2" value="Sign up" id="btnServiceSeeker" (click)="navigateJobSeeker()" />
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
</div>
