import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthToken } from '../../constants/api-portal-constant';

@Injectable({
  providedIn: 'root'
})
export class SigninValidationService implements CanActivate {
  private jwtHelper: JwtHelperService
  constructor(private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }
  canActivate() {

    var token = localStorage.getItem(AuthToken.AuthTokenKey);
    //debugger;
    if (token && !this.jwtHelper.isTokenExpired(token)) {
       this.router.navigate(["/"]);
      //return false;

    }

    return true;
  }
}
