import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
//import { MatPaginator, MatTableDataSource, PageEvent } from '@angular/material'
import { ServiceProvider } from '../../model/service-provider';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import { filter } from 'rxjs/operators';
import { Filters } from '../../implementations/filters';
import { SearchComponent } from '../search/search.component';
import { Tag } from '../../model/tag';
import { PortalRole } from '../../constants/api-portal-constant';
import { Title } from '@angular/platform-browser';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-servceprovider',
  templateUrl: './servceprovider.component.html',
  styleUrls: ['./servceprovider.component.css']
})

export class ServceproviderComponent implements OnInit, AfterViewInit {
  
  public displayedColumns: string[] = ['firstName', 'expertise', 'education','city', 'member','rating', 'id','tag'];
  public dataSource: any;
  ELEMENT_DATA: ServiceProvider[];
  public imageUrl: any;
  public imagePath: string;
  public events: string[] = [];
  public opened: boolean;
  public jobProvider: string = PortalRole.JobProvider;
  public filter: Filters = new Filters();
 
  //public shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  selection = new SelectionModel<ServiceProvider>(true, []);
  @ViewChild(SearchComponent, { static: false }) search: SearchComponent;
  searchData: any;
  constructor(private providerService: ProvidersService, private router: Router, private title: Title) {
    
  }
  protected paginator: MatPaginator;
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  //@ViewChild(MatPaginator) paginator: MatPaginator;
  length: number = 100;;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  pageIndex: number = 0;
  skip: number = 0;
  ngOnInit() {
    this.title.setTitle("All services-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    //this.dataSource.paginator = this.paginator;
    //debugger
    this.getProviders();

  }
  ngAfterViewInit(): void {   
    var data = this.search.data;
    if (data != undefined) {
      this.dataSource = new MatTableDataSource(this.search.data);
    }   
  }
  pageEvent: PageEvent;
  
  receiveData($event) {
    var data = $event;
    this.searchData = <Filters>data;
    this.dataSource = new MatTableDataSource(this.searchData);
  }

  receiveFilter($event) {
    //debugger
    var data = $event;
    this.searchData = <Filters>data;
    this.dataSource = new MatTableDataSource(this.searchData);

  }

  getProviders() {    
    
  }

  navigate(id) {
    this.router.navigate(["/serviceprofile"], { queryParams: { userId: id } });
  }

  getImageUrl(fileName: string) {   
    return "assets/" + fileName;
  }
  changePage(event) {   
    this.filter.pageNumber = event.pageIndex;
    this.filter.pageSize = event.pageSize;
    this.getProviders();
    if (this.ELEMENT_DATA != undefined && this.length > this.ELEMENT_DATA.length) {
      if (this.pageIndex < event.pageIndex) {
        this.skip = this.skip + this.pageSize;
      }
    }
  }

  addTag(id: any) {
    var model: Tag;
    model = {
      id: id, userType: null
    };
    this.providerService.addTag(model).subscribe((data: any) => {
       
        //if (this.portfolio != undefined && this.portfolio.roleName == PortalRole.JobProvider) {
        
      });
  }

}


