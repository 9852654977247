import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
//import { MatTableDataSource } from '@angular/material';
import { country } from '../../model/country';
import { UsefulLinkModel } from '../../model/useful-link-model';
import { AdminService } from '../../services/Admin/admin.service';
import { CoreService } from '../../services/core/core.service';
import { IpServiceService } from '../../services/ip-service.service';

@Component({
  selector: 'app-usefullink',
  templateUrl: './usefullink.component.html',
  styleUrls: ['./usefullink.component.css']
})
export class UsefullinkComponent implements OnInit {
  public allCountry?: country[];
  public countryCode?: string;
  public usefulLinkForm: UntypedFormGroup;
  private ipAddress?: string;
  public fileData: FormData = new FormData();
  public successMsg?: string;
  public errMessage?: string;
  data: any;
  constructor(private coreService: CoreService, private fb: UntypedFormBuilder,
    private ipService: IpServiceService, private adminService: AdminService  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getCountry();
    this.getIP();
  }
  createForm() {
    this.usefulLinkForm = this.fb.group({
      link: new UntypedFormControl('', [Validators.required]),
      countryCode: new UntypedFormControl('', [Validators.required]),
      countryName: new UntypedFormControl('', [Validators.required]),
      companyName: new UntypedFormControl('', [Validators.required])
     
    });
  }
  getCountry() {
    this.coreService.getAllCountry()
      .subscribe((response: country[]) => {
        this.allCountry = response;
      });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      this.getCarrierLink();

    });
  }

  save() {
    this.usefulLinkForm.value.countryName = this.allCountry.filter(x => x.countryCode == this.usefulLinkForm.value.countryCode)[0].countryName;
    let credentials1 = JSON.stringify(this.usefulLinkForm.value);
    this.fileData.append('data', credentials1);
    let credentials = this.fileData;

    this.adminService.save(credentials).subscribe(response => {
    
      let info = (<any>response).success;
      this.successMsg = "Link added successfully";

    }, err => {
      this.errMessage="Error occured";
    });
  }

  getCarrierLink() {
    this.adminService.getCarrierLink(this.ipAddress).subscribe((response: UsefulLinkModel[]) => {
      this.data = new MatTableDataSource(response);
     /* this.data.paginator = null;*/
    }, err => {
      this.errMessage = "Error occured";
    });
  }
}
