import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
//import { MatTableDataSource } from '@angular/material';
import { UsefulLinkModel } from '../../../model/useful-link-model';
import { AdminService } from '../../../services/Admin/admin.service';
import { IpServiceService } from '../../../services/ip-service.service';

@Component({
  selector: 'app-carriersite',
  templateUrl: './carriersite.component.html',
  styleUrls: ['./carriersite.component.css']
})
export class CarriersiteComponent implements OnInit, AfterViewInit {
  private ipAddress: string;
  //public dataSource: any;
  public displayedColumns: string[] = ['link', 'countryName'];
  constructor(private ipService: IpServiceService, private adminService: AdminService) {
    //if (this.data != undefined) {
    //  this.dataSource = new MatTableDataSource(this.data);
    //}
  }
  ngAfterViewInit(): void {
    //if (this.data != undefined) {
    //  this.dataSource = new MatTableDataSource(this.data);
    //}
  }

  @Input() dataSource:any;
  ngOnInit(): void {
    //if (this.data != undefined) {
    //  this.dataSource = new MatTableDataSource(this.data);
    //}
    this.getIP();
  }
  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      //this.adminService.getCarrierLink(this.ipAddress);
     
    });
  }
}
