import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'formatData' })
export class formatData implements PipeTransform {
  transform(value1: string, value2: string,value3:string): string {   
    var newStr = value1.replace("{0}", value2);
    newStr = newStr.replace("{1}", value3);
    return newStr;
  } 
}
