import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { CoreService } from '../../services/core/core.service';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { IpServiceService } from '../../services/ip-service.service';
import { SetBrowserService } from '../../services/set-browser.service';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { ActivatedRoute } from '@angular/router';
import { SpecialLookup } from '../../model/special-lookup';
import { Yearmodel } from '../../model/yearmodel';
import { Monthmodel } from '../../model/monthmodel';
import { Daymodel } from '../../model/daymodel';
import { HiredModel } from '../../model/hired-model';
import { Subjects } from '../../model/subjects';
import { LocalCookieService } from '../../services/cookie/local-cookie.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hireme',
  templateUrl: './hireme.component.html',
  styleUrls: ['./hireme.component.css']
})
export class HiremeComponent implements OnInit {
  public hireMeForm: UntypedFormGroup;
  public userId: any;
  public userType: string;
  public specialLookup: SpecialLookup;
  public allYears: Yearmodel[];
  public allMonths: Monthmodel[];
  public allDays: Daymodel[];
  public selectedCatSubIds: number[];
  public hiredModel: HiredModel;
  public subjects: Subjects[];
  public successMsg: string;
  public errMessage: string;
  public selectedYear: number;
  public selectedDay: number;
  public selectedMonth: number;
  public fullName: string;
  public isSubmitted: boolean;
  private ipAddress: string | undefined;
  constructor(private fb: UntypedFormBuilder,
    private coreService: CoreService,
    private providersService: ProvidersService,
    private ipService: IpServiceService,
    private setbrowserService: SetBrowserService,
    private portalnInfo: PortalninformationService,
    private activatedroute: ActivatedRoute,
    private localCookieService: LocalCookieService,
    private title: Title) {

    this.createForm();
    this.userId = this.activatedroute.snapshot.queryParamMap.get('userId') || 0;
    this.userType = this.localCookieService.getRole();
  }


  ngOnInit() {
    this.title.setTitle("Hire-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.getIP();
    this.getYears();
    this.getMonths();
    this.getDays();
    
  }
  createForm() {
    this.hireMeForm = this.fb.group({
      firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(80)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(80)]),
      country: new UntypedFormControl('', [Validators.required]),
      //userType: '',
      id: Number,
      day: Number,
      month: Number,
      year: Number,
      selectedSubjectId: Number,
      userSubjectCategory: Int32Array,
      ip: String,
      divisionId: Number,
      districtId: Number,
      thanaId: Number,
      salary: Number,
      city: '',
      thanaName: '',
      districtName: '',
      divisionName: '',
      selectedYear: Number,
      selectedDay: Number,
      selectedMonth: Number,
      selectedCatSubIds: [],
      hireDate: ''
    });
  }
  saveHire() {
   // debugger
    this.hireMeForm.value.id = this.userId;
    this.hireMeForm.value.selectedCatSubIds = this.selectedCatSubIds;
    this.hireMeForm.value.hireDate = this.selectedMonth + "/" + this.selectedDay + "/" + this.selectedYear;
    this.hireMeForm.value.ip = this.ipAddress;
    let credentials1 = JSON.stringify(this.hireMeForm.value);

    var fileData: FormData = new FormData();
    fileData.append('data', credentials1);
    let credentials = fileData;
    if (credentials != undefined) {
      this.providersService.saveHire(credentials).subscribe(response => {
        let info = (<any>response).success;
        if (info != undefined) {
          this.successMsg = info;
          this.errMessage = null;
        }
        else {
          this.errMessage = response.errMsg;
          this.successMsg = null;
        }
      }, err => {

      });
    }

  }
  getPortalInfo(ip: string) {
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
        this.getServiceProviderProfile();
      });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      this.getPortalInfo(this.ipAddress);
      
    });
  }

  getYears() {

    this.coreService.getHireYear()
      .subscribe((data: Yearmodel[]) => {
        //debugger;
        this.allYears = data;
      });
  }
  getMonths() {
    this.coreService.getAllMonths()
      .subscribe((data: Monthmodel[]) => {
        this.allMonths = data;
      });
  }

  getDays() {
    this.coreService.getAllDays()
      .subscribe((data: Daymodel[]) => {
        this.allDays = data;

      });
  }

  getServiceProviderProfile() {
    this.providersService.getServiceProviderProfile(this.userId)
      .subscribe((data: HiredModel) => {
        this.hiredModel = data;
        if (this.hiredModel.educationLevel == '' || !this.hiredModel.educationLevel) {
          this.hiredModel.educationLevel = "None";
        }
        
        this.getCategorySubjects(this.hiredModel.categoryId, this.hiredModel.educationLevel);
        this.populateField();
      });
  }

  populateField() {
    this.hireMeForm.get("firstName").setValue(this.hiredModel.firstName);
    this.hireMeForm.get("lastName").setValue(this.hiredModel.lastName);
    this.hireMeForm.get("city").setValue(this.hiredModel.city);
    this.hireMeForm.get("thanaName").setValue(this.hiredModel.thanaName);
    this.hireMeForm.get("districtName").setValue(this.hiredModel.districtName);
    this.hireMeForm.get("divisionName").setValue(this.hiredModel.divisionName);
  }

  getCategorySubjects(selectedCategoryIds: number, educationLevel: string) {

    this.coreService.GetSubjectCategory(selectedCategoryIds, educationLevel, this.specialLookup.actualCountryCode, this.userType)
      .subscribe((data: Subjects[]) => {
        this.subjects = data;
      });
  }

  filterDays() {

  }
}
