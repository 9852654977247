<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-light sticky" role="navigation"  id="navbar">
    <div class="container-fluid">
      <img src="assets/logo/logo.jpg" width="50" height="50" class="p-0 border-0" style="background-color: lightblue;" />
      &nbsp; <a class="navbar-brand" [routerLink]='["/"]'><b>Finding Professional</b></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user==null)">
            <a class="nav-link text-dark" [routerLink]='["/join"]'>Subscription</a>
          </li>
          <li class="nav-item dropdown" dropdown *ngIf="(user!=undefined && user?.isAuthenticatedUser)">
            <a dropdownToggle type="button" class="nav-link dropdown-toggle text-dark">
              Profile<span class="caret"></span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary bg-light" role="menu">
              <a class="dropdown-item bg-light" [routerLink]='["/edit"]'>Edit</a>
              <a class="dropdown-item bg-light" [routerLink]='["/hiringHistory"]'>Hiring History</a>
              <a class="dropdown-item bg-light" [routerLink]='["/reviews"]' *ngIf="(user?.roleName==user?.userRole?.jobSeeker || user?.roleName==user?.userRole?.admin)">
                {{userType}} Your reviews
              </a>
            </div>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && (user?.roleName==user?.userRole?.jobSeeker || user?.roleName==user?.userRole?.jobProvider || user?.roleName==user?.userRole?.admin)">
            <a class="nav-link text-dark" [routerLink]='["/servieprovider"]'>
              {{
                user?.menu?.menuName
              }}
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser && user?.roleName==user?.userRole?.jobProvider && isPaymentEnable)">
            <a class="nav-link text-dark" [routerLink]='["/payment"]'>Payment</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser)">
            <a class="nav-link text-dark" [routerLink]='["/conversation"]'>Conversation</a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user==null)">
            <a class="nav-link text-dark" [routerLink]='["/loginapi"]'>Login</a>
          </li>
          <li class="nav-item dropdown" dropdown *ngIf="(user!=null && (user?.isAuthenticatedUser))" style="z-index: 100">
            <a dropdownToggle class="nav-link dropdown-toggle text-dark">
              {{ user?.fullName }}<span class="caret"></span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item bg-light" [routerLink]='["/log-out"]'>Log out</a>

            </div>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link text-dark" [routerLink]='["/contact-us"]'>Contact us</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link text-dark" [routerLink]='["/aboutus"]'>About Us</a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && user?.roleName==adminUser">
            <a class="nav-link text-dark" [routerLink]='["/termsAndCondition"]'>Terms and Conditions</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && user?.roleName==adminUser">
            <a class="nav-link text-dark" [routerLink]='["/link"]'>Carrier Link</a>
          </li>


          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && user?.roleName==adminUser">
            <a class="nav-link text-dark" [routerLink]='["/addfaq"]'>Faq</a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link text-dark" [routerLink]='["/faq"]'>FAQ</a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link text-dark" [routerLink]='["/whattodo"]'>What we do</a>
          </li>
        </ul>
        <!--<form class="form-inline my-2 my-lg-0">
    <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
    <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
  </form>-->
      </div>
    </div>
  </nav>
 
 
  <!--<nav class='navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-blue border-bottom box-shadow mb-3 ml-0 p-0 pos'>
    <div class="container container-margin">
      <img src="assets/logo/logo.jpg" width="50" height="50" class="p-0 border-0" style="background-color: lightblue;" />
      &nbsp; <a class="navbar-brand" [routerLink]='["/"]'>Finding Professional</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]='{"show": isExpanded}'>
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
            <a class="nav-link text-dark" [routerLink]='["/"]'>Home</a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user==null)">
            <a class="nav-link text-dark" [routerLink]='["/join"]'>Subscription</a>
          </li>
          <li class="nav-item dropdown" dropdown *ngIf="(user!=undefined && user?.isAuthenticatedUser)">
            <a dropdownToggle type="button" class="nav-link dropdown-toggle text-dark">
              Profile<span class="caret"></span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" [routerLink]='["/edit"]'>Edit</a>
              <a class="dropdown-item waves-light" [routerLink]='["/hiringHistory"]'>Hiring History</a>
              <a class="dropdown-item waves-light" [routerLink]='["/reviews"]' *ngIf="(user?.roleName==user?.userRole?.jobSeeker || user?.roleName==user?.userRole?.admin)">
                {{userType}} Your reviews
              </a>
            </div>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && (user?.roleName==user?.userRole?.jobSeeker || user?.roleName==user?.userRole?.jobProvider || user?.roleName==user?.userRole?.admin)">
            <a class="nav-link text-dark" [routerLink]='["/servieprovider"]'>
              {{
                user?.menu?.menuName
              }}
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser && user?.roleName==user?.userRole?.jobProvider && isPaymentEnable)">
            <a class="nav-link text-dark" [routerLink]='["/payment"]'>Payment</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser)">
            <a class="nav-link text-dark" [routerLink]='["/conversation"]'>Conversation</a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user==null)">
            <a class="nav-link text-dark" [routerLink]='["/loginapi"]'>Login</a>
          </li>
          <li class="nav-item dropdown" dropdown *ngIf="(user!=null && (user?.isAuthenticatedUser))">
            <a dropdownToggle class="nav-link dropdown-toggle text-dark">
              {{ user?.fullName }}<span class="caret"></span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" [routerLink]='["/log-out"]'>Log out</a>

            </div>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link text-dark" [routerLink]='["/contact-us"]'>Contact us</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link text-dark" [routerLink]='["/aboutus"]'>About Us</a>
          </li>

          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && user?.roleName==adminUser">
            <a class="nav-link text-dark" [routerLink]='["/termsAndCondition"]'>Terms and Conditions</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && user?.roleName==adminUser">
            <a class="nav-link text-dark" [routerLink]='["/link"]'>Carrier Link</a>
          </li>


          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="(user!=undefined && user?.isAuthenticatedUser) && user?.roleName==adminUser">
            <a class="nav-link text-dark" [routerLink]='["/addfaq"]'>Faq</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>-->
</header>
