import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { PasswordResetModel } from '../../model/password-reset-model';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MustMatch } from '../../helper/must-match.validator';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {
  public passwordResetForm: UntypedFormGroup;
  public userName: string;
  public token: string;
  public successMsg: string;
  public errMessage: string;
  public passpordReset: PasswordResetModel;
  constructor(public fb: UntypedFormBuilder, public loginService: LoginService,
    public Activatedroute: ActivatedRoute) {
    this.userName = this.Activatedroute.snapshot.queryParamMap.get('userId') || null;
    this.token = this.Activatedroute.snapshot.queryParamMap.get('code') || null;
  }

  ngOnInit(): void {
    this.createForm();
  }
  resetPassword() {  
    if (this.dataValidation) {
      this.passwordResetForm.value.emailAddress = this.userName;
      this.passwordResetForm.value.token = this.token;

      let passwordResetJson = JSON.stringify(this.passwordResetForm.value);
      this.loginService.resetPassword(passwordResetJson)
        .subscribe((data: any) => {
          let info = (<any>data).success;
          if (info != undefined) {
            this.successMsg = info;
          }
          else {
            this.errMessage = data.errMsg;
            this.successMsg = null;
          }
        },
          err => {

            var rr = err;
          });
    }

  }

  createForm() {
    this.passwordResetForm = this.fb.group({     
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(80)]),
      confirmNewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(80)]),
      emailAddress: new UntypedFormControl('', [Validators.required]),
    });
  }

  dataValidation(): boolean {
    var htmel: HTMLElement;
    var errorMsg = "";
    var isValid = true;
   
  var password = this.passwordResetForm.get("newPassword").value;
    htmel = document.getElementById("errPassword");
    htmel.innerHTML = "";
    if (password != undefined && password == '') {

      errorMsg = "<span class='alert alert-danger d-block'>Password can not be empty</span>"
      htmel.innerHTML = errorMsg;
      this.passwordResetForm.controls['newPassword'].setErrors({ 'incorrect': true });
      isValid = false;
    }
   

    var confirmPassword = this.passwordResetForm.get("confirmNewPassword").value;
    htmel = document.getElementById("confirmErrPassword");
    htmel.innerHTML = "";
    if (confirmPassword != undefined && confirmPassword == '') {

      errorMsg = "<span class='alert alert-danger d-block'>Confirm password can not be empty</span>"
      htmel.innerHTML = errorMsg;
      this.passwordResetForm.controls['confirmErrPassword'].setErrors({ 'incorrect': true });
      isValid = false;
    }
   
    if (password != confirmPassword) {
      errorMsg = "<span class='alert alert-danger d-block'>Password and confirm password are not same</span>"
      htmel.innerHTML = errorMsg;
      this.passwordResetForm.controls['newPassword'].setErrors({ 'incorrect': true });
      this.passwordResetForm.controls['confirmErrPassword'].setErrors({ 'incorrect': true });
      isValid = false;
    }
    
    return isValid;
  }
  
}

