<div class="card">
  <form [formGroup]="faqForm" (ngSubmit)="save()" novalidate>
    <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
    <h3 class="regblock"><i class="fa fa-question-circle" aria-hidden="true"> Frequently asked question</i></h3>
    <div class="form-group form-inline">
      <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Category</label>
      <ng-select [items]="allCategory"
                 bindLabel="categoryName"
                 bindValue="id"
                 [multiple]="false"
                 placeholder="Select Subjects"
                 formControlName="categoryId"
                 [(ngModel)]="categoryId"
                 class="col-xs-12 col-sm-7 col-md-5 col-lg-4 p-0">
      </ng-select>
    </div>
    <div class="form-group form-inline">
      <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Question</label>
      <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
             formControlName="question"
             id="question" />
    </div>
    <div class="form-group form-inline">
      <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Answer</label>
      <editor apiKey="u370vgj6e4u0hrmc7sz9zrjm0wljblju3qbvjfmxvl81ndxs"
              formControlName="answer" class="col-xs-12 col-sm-12 col-md-9 col-lg-9"
              id="answer"
              [init]="{
               height: 200,
               menubar: false,
               branding:false,
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount'
                     ],
                     toolbar:
                       'undo redo | formatselect | bold italic backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help'}">

      </editor>
    </div>
    <div class="form-group form-inline">
      <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Active</label>
      <input type="checkbox" class="checkbox" style="float:left; margin:0px;"
             formControlName="active"
             id="active" />
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary">
        Save
      </button>
    </div>
  </form>

  <!--<app-carriersite [dataSource]="data"></app-carriersite>-->
</div>



