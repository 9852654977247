import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthToken } from '../../constants/api-portal-constant';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  private baseUrl: string;
  private routePrefix: string = 'api/core';
  private searchRoutePrefix: string = 'api/search';

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl;
  }

  getAllCountry(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix);
  }

  getAllCategory(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/cat");
  }

  getAllState(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/state");
  }

  getAllYear(userType: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/year/" + userType);
  }

  getAllMonths(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/month");
  }

  getAllDays(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/day");
  }

  GetEducationYear(yearSelection: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/educationYear/" + yearSelection);
  }

  GetAllDimploma(selecttor: string, countryCode: string, userType: string, educationLvl: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/" + selecttor + "/" + countryCode + "/" + userType + "/" + educationLvl);
  }

  GetAllSubjects(diplomaId: number, countryCode, categoryId: number, educationLevel: string, userType: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/subject/" + diplomaId + "/" + countryCode + "/" + categoryId + "/" + educationLevel + "/" + userType);
  }

  GetSubjectCategory(categoryId: number, educationLevel: string, countryCode: string, userType: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/subjectcat/" + categoryId + "/" + educationLevel + "/" + countryCode + "/" + userType);
  }

  getAvailableUser(userName: string) {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/userexist/" + userName);
  }

  getSearchCriteria(countrtCode: string) {
    if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
      return this.httpClient.get(this.baseUrl + this.searchRoutePrefix + "/" + countrtCode);
    }
    else {
      return null;
    }
  }

  getCarousel(ip: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/carousel/" + ip);
  }

  GetAllDivisions(countryCode: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/div/" + countryCode);
  }

  GetAllDistricts(divisionId: number): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/dist/" + divisionId);
  }

  GetAllThana(districtId: number): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/thana/" + districtId);
  }

  getSalary(countryCode: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/salary/" + countryCode);
  }

  getExperienceLookUp(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/experience");
  }

  getCategory(categoryId: number) {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/category/" + categoryId);
  }

  getHireYear(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/hireYear");
  }

  getStopServiceCause(): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/cause");
  }

  getLabelByCountry(countryCode: string, userType: string) {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/labelByCountry/" + countryCode + "/" + userType);
  }
  getAllAccountFees(countryCode: string): any {
   
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/account-fee/${countryCode}`);
  }

  getTermsAndConditions(countryCode: string): any {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/termsandconditions/${countryCode}`);
  }
  getPaymentOptions(countryCode: string): any {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/paymentoptions/${countryCode}`);
  }

  getJobSeekerEducation(countryCode: string) {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/job-seeker-education/${countryCode}`);
  }
  getJobProviderSubjects(countryCode: string) {
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}/job-provider/${countryCode}`);
  }
}
