import { Component, OnInit } from '@angular/core';
import { HiredModel } from '../../model/hired-model';
import { Subjects } from '../../model/subjects';
import { Daymodel } from '../../model/daymodel';
import { Monthmodel } from '../../model/monthmodel';
import { Yearmodel } from '../../model/yearmodel';
import { SpecialLookup } from '../../model/special-lookup';
import { FormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { SetBrowserService } from '../../services/set-browser.service';
import { CoreService } from '../../services/core/core.service';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { IpServiceService } from '../../services/ip-service.service';
import { ServiceStopCauseModel } from '../../model/service-stop-cause-model';
import { ServiceStopCause } from '../../constants/api-portal-constant';
import { LocalCookieService } from '../../services/cookie/local-cookie.service';

@Component({
  selector: 'app-stopservice',
  templateUrl: './stopservice.component.html',
  styleUrls: ['./stopservice.component.css']
})
export class StopserviceComponent implements OnInit {
  public stopServiceForm: UntypedFormGroup;
  public userId: any;
  public userType: string;
  public specialLookup: SpecialLookup;
  public selectedServiceCauseId: number;
  public hiredModel: HiredModel;
  public serviceStopCauses: ServiceStopCauseModel[];
  public successMsg: string;
  public errMessage: string;
  public isOtherCause: boolean;
  private dtDate: Date;
  constructor(private fb: UntypedFormBuilder,
    private coreService: CoreService,
    private providersService: ProvidersService,
    private ipService: IpServiceService,
    private setbrowserService: SetBrowserService,
    private portalnInfo: PortalninformationService,
    private activatedroute: ActivatedRoute,
    private localCookieService: LocalCookieService) {

    this.createForm();
    this.userId = this.activatedroute.snapshot.queryParamMap.get('userId') || 0;
    this.userType = this.localCookieService.getRole();
  }


  ngOnInit() {
    this.getIP();
    this.getStopServiceCause();
    this.getServiceProviderProfile();
  }
  createForm() {
    this.stopServiceForm = this.fb.group({
      firstName: '',
      lastName: '',
      country: '',
      userType: '',
      id: Number,
      serviceStopDate: Date,
      ip: String,
      selectedServiceCauseId: Number,   
      otherCause: '',
      comments:''
    });
  }
  saveStopService() {
     
    this.stopServiceForm.value.id = this.userId;
    //this.stopServiceForm.value.selectedServiceCauseId = this.selectedServiceCauseId;
    this.stopServiceForm.value.serviceStopDate = this.dtDate;
    let credentials1 = JSON.stringify(this.stopServiceForm.value);

    var fileData: FormData = new FormData();
    fileData.append('data', credentials1);
    let credentials = fileData;
    if (credentials != undefined) {
      this.providersService.stopService(credentials).subscribe(response => {
        let info = (<any>response).success;
        if (info != undefined) {
          this.successMsg = info;
          this.errMessage = null;
        }
        else {
          this.errMessage = response.errMsg;
          this.successMsg = null;
        }
      }, err => {

      });
    }

  }
  getPortalInfo(ip: string) {
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
      });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      var ipAddress = res.ip;
      this.getPortalInfo(ipAddress);
    });
  }

  getServiceProviderProfile() {
    this.providersService.getServiceProviderProfile(this.userId)
      .subscribe((data: HiredModel) => {
        this.hiredModel = data;
        this.populateField();
      });
  }

  populateField() {
    this.stopServiceForm.get("firstName").setValue(this.hiredModel.firstName);
    this.stopServiceForm.get("lastName").setValue(this.hiredModel.lastName);
  }
  getStopServiceCause() {
    this.coreService.getStopServiceCause()
      .subscribe((data: ServiceStopCauseModel[]) => {
        this.serviceStopCauses = data;
      });
  }

  getOtherCause() {
    var cause = this.serviceStopCauses.filter(x => x.id == this.selectedServiceCauseId)[0].causeName;
    if (cause == ServiceStopCause.Other) {
      this.isOtherCause = true;
    }
    else {
      this.isOtherCause = false;
    }
  }
  getDate(stopServiceDate: Date) {
    this.dtDate= stopServiceDate;
  }
}

