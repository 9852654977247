import { Component, OnInit, Input } from '@angular/core';
import { EmailService } from '../../services/email/email.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ComposeMail } from '../../model/compose-mail';

@Component({
  selector: 'app-email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.css']
})
export class EmailDetailsComponent implements OnInit {
  public composeMail: ComposeMail;
  public mailToUserId: any;
  public fullName: string;
  constructor(private emailService: EmailService, private router: Router) {

    
  }

  ngOnInit() {
    this.getMailDetails();
  }
  @Input()
   mailboxType: string;

  @Input()
  messageId: number;

  getMailDetails() {

    this.emailService.getMail(this.mailboxType, this.messageId).subscribe((response: ComposeMail) => {
      //debugger;
      this.composeMail = response;
    
    }, err => {
      //debugger
    });
  }
}
