
<div class="card border-1 p-0 w-100">
  <div class="card-header text-center"><h1 class="regblock"><i class="fas fa-wallet">&nbsp;Payment</i></h1></div>
  <div class="card-body">
    <form [formGroup]="paymentForm" (ngSubmit)="savePayment()" novalidate>
      <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
      <div class="form-group form-inline" *ngIf="specialLookup?.actualCountryCode==countryCode">
        <label class="col-xs-12 col-sm-5 col-md-2 col-lg-2">Payment Option</label>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="bikash" value="Bikash"
                 formControlName="paymentOpt" name="paymentOpt" (change)="getPaymentOption()">
          <label class="custom-control-label" for="bikash">Bkash/Nagad</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="credirCard" value="CreditCard"
                 formControlName="paymentOpt" name="paymentOpt" (change)="getPaymentOption()">
          <label class="custom-control-label" for="credirCard">Credit Card</label>
        </div>

      </div>
      <div id="errPayOpt"></div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-2 col-lg-2">Subscription Option</label>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="month1" value="1"
                 formControlName="subscriptionOption" name="subscriptionOption" (change)="getSubscriptionOption()">
          <label class="custom-control-label" for="month1">1 Month</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="month3" value="3"
                 formControlName="subscriptionOption" name="subscriptionOption" (change)="getSubscriptionOption()">
          <label class="custom-control-label" for="month3">3 Months</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="month6" value="6"
                 formControlName="subscriptionOption" name="subscriptionOption" (change)="getSubscriptionOption()">
          <label class="custom-control-label" for="month6">6 Months</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="month12" value="12"
                 formControlName="subscriptionOption" name="subscriptionOption" (change)="getSubscriptionOption()">
          <label class="custom-control-label" for="month12">12 Months</label>
        </div>

      </div>
      <div id="subsOption"></div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-2 col-lg-2">Billing Option</label>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="allMonth" value="1"
                 formControlName="billingOption" name="billingOption" (change)="getBillingOption()">
          <label class="custom-control-label" for="allMonth">One time</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="monthly" value="3"
                 formControlName="billingOption" name="billingOption" (change)="getBillingOption()">
          <label class="custom-control-label" for="monthly">Monthly</label>
        </div>
      </div>
      <div id="errBillingOpt"></div>
      <div class="form-group form-inline" *ngIf="totalAmount!=undefined && totalAmount>0">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2"><b>Billing amount</b></label>
        <label class="col-xs-12 col-sm-5 col-md-5 col-lg-5"><b>{{specialLookup?.currencySymbol}} {{totalAmount}}</b> in {{billingOptionName}} </label>
      </div>
      <div *ngIf="isBikashOption">
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Bikash/Nagad number </label>
          <input class="form-control col-xs-12 col-sm-7 col-md-3 col-lg-3"
                 formControlName="phoneNumber" maxlength="12" mask="000 000 0000"
                 id="phoneNumber" placeholder="Bikash/Nagad phone number" />
        </div>
        <div id="errPhoneNum"></div>
      </div>

      <div *ngIf="isCreditCardOption">
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Card number </label>
          <input class="form-control col-xs-12 col-sm-7 col-md-3 col-lg-3"
                 formControlName="creditCardNumber" maxlength="19" mask="0000 0000 0000 0000"
                 id="creditCardNumber" placeholder="Card number" />
        </div>
        <div id="errCreditCard"></div>
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2 cc-cvc">CVV </label>
          <input class="form-control col-xs-12 col-sm-3 col-md-1 col-lg-1"
                 formControlName="cvv"
                 id="cvv" placeholder="CVV" mask="000" />
        </div>
        <div id="errCVV"></div>
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Expiration date </label>
          <input class="form-control col-xs-12 col-sm-3 col-md-1 col-lg-1"
                 formControlName="expirationYear" mask="00/0000"
                 id="expirationYear" placeholder="MM/YYYY" />
        </div>
        <div id="errExpiration"></div>
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Full name </label>
          <input class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
                 formControlName="fullName"
                 id="fullName" />
        </div>
        <div id="errFullName"></div>
        <div *ngIf="paymentForm.get('fullName').errors && isSubmitted" class="alert alert-danger">
          <div *ngIf="paymentForm.get('fullName').hasError('required')">
            Full name is required.
          </div>
          <div *ngIf="paymentForm.get('firstName').hasError('minlength')">
            Full name required at least 4 characters.
          </div>
          <div *ngIf="paymentForm.get('firstName').hasError('maxlength')">
            Max 80 characters are allowed for Full name.
          </div>
        </div>
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Address</label>
          <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
                 formControlName="address"
                 id="address" />
        </div>
        <div id="errAddress"></div>
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">City</label>
          <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
                 formControlName="city"
                 id="city" />
        </div>
        <div id="errCity"></div>
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Zip/Postal code</label>
          <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
                 formControlName="zipcode"
                 id="zipcode" />
        </div>
        <div id="errZipcode"></div>
        <div class="form-group form-inline">
          <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Country</label>
          <ng-select [items]="country"
                     bindLabel="countryName"
                     bindValue="countryCode"
                     [multiple]="false"
                     placeholder="Select Country"
                     formControlName="selectedCountryCode"
                     [(ngModel)]="selectedCountryCode"
                     class="col-xs-12 col-sm-7 col-md-5 col-lg-4 p-0">
          </ng-select>
        </div>

      </div>
      <div id="errCountry"></div>
      <div class="form-group">
        <button type="submit" class="btn btn-success">
          Save
        </button>
      </div>
      <div class="form-group form-inline">
        <i class="fab fa-cc-visa credit-card-icon"></i>
        <i class="fab fa-cc-mastercard credit-card-icon"></i>
        <i class="fa fa-credit-card credit-card-icon"></i>
        <i class="fab fa-cc-amex credit-card-icon"></i>
        <i class="fab fa-cc-paypal credit-card-icon"></i>
      </div>
    </form>
  </div>
</div>




