<app-spinner *ngIf="loader.loading | async"></app-spinner>
<header>
  <app-nav-menu></app-nav-menu>
</header>
<body>
  <br />
  <div class="container screen-width container-margin p-0 container-padding">
    <router-outlet></router-outlet>
  </div>
</body>
<footer class="footer footer_bg_1" style="position:relative;clear:both">
  <app-footer></app-footer>
</footer>

