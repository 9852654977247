import { Injectable } from '@angular/core';
import { AuthenticatedUser } from '../model/authenticated-user';

@Injectable({
  providedIn: 'root'
})
export class SetBrowserService {

  constructor() { }
  setUserInfoKey(authUser:AuthenticatedUser) {
    localStorage.setItem("role", authUser.roleName);
  }
  getUserInfoKey(key: string): string {
    return localStorage.getItem(key);
  }
}
