
<div id="main-wrapper">

  <div class="columns-block">
    <div class="left-col-block blocks">
      <header class="header theiaStickySidebar">
        <div class="profile-img" *ngIf="portfolio?.imagePath && !portfolio?.image">
          <img width="100" height="100" [src]="portfolio?.imagePath" class="img-responsive" alt="" />
        </div>
        <div class="profile-img" *ngIf="portfolio?.image">
          <img width="100" height="100" [src]="portfolio?.image" class="img-responsive" alt="" />
        </div>
        <div class="content">
          <h1>{{portfolio?.firstName}} {{portfolio?.lastName}}</h1>
          <span class="lead">{{portfolio?.title}}</span><br />
          <div class="about-text" *ngIf="portfolio?.memberShip">
            <p>
              <b>Member since:</b> {{portfolio?.memberShip}}
            </p>
          </div>
          <div class="about-text" *ngIf="portfolio?.roleName !=jobSeeker">
            <p>
              <span [innerHtml]="portfolio?.aboutMe"></span>
            </p>
          </div>

          <div class="about-text" *ngIf="portfolio?.sex">
            <p>
              <b>Sex:</b> {{portfolio?.sex}}
            </p>
          </div>


          <div class="about-text">
            <div>
              <address>
                {{portfolio?.roadnumName}}
                <p *ngIf="portfolio?.aptHouseNumber !=undefined"><b>House/Apt#</b> {{portfolio?.aptHouseNumber}}</p>
                <p *ngIf="portfolio?.sectorVillage !=undefined">{{portfolio?.sectorVillage}}</p>
                <p *ngIf="portfolio?.city !=undefined">{{portfolio?.city}}</p>
                <p>
                  {{portfolio?.thanaName}}, {{portfolio?.districtName}}
                </p>
              </address>
            </div>
          </div>

        </div>
      </header>
    </div>

    <div class="right-col-block blocks">
      <div class="theiaStickySidebar">
        <section class="expertise-wrapper section-wrapper gray-bg">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12" *ngIf="portfolio?.firstName">
                <button id="btn" class="btn btn-primary pull-left mb-2 mr-1 button-border" (click)="navigatetoEmail()">
                  Contact {{portfolio?.firstName}} {{portfolio?.lastName}}
                </button>
                <button id="btn" class="btn btn-primary pull-left mb-2 button-border {{enableDisable}}" (click)="navigatetoHire()" *ngIf="(userType==jobSeeker || userType==adminRole) && enableDisable !='disabled'">
                  Hire {{portfolio?.firstName}} {{portfolio?.lastName}}
                </button>
                <button id="btn" class="btn btn-primary pull-left mb-2 button-border" (click)="navigatetoStopService()" *ngIf="(userType==jobSeeker || userType==adminRole) && enableDisable =='disabled'">
                  Stop Service
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" *ngIf="portfolio?.roleName !=jobSeeker && portfolio?.experience">
                <div class="expertise-item">
                  <h3>{{portfolio?.experienceLabel?.experienceRequirement}}</h3>
                  <p>
                    <span [innerHtml]="portfolio?.experience"></span>
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="expertise-item">
                  <h3>{{portfolio?.experienceLabel?.expertiseLooking}} </h3>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="content-item">
                        <p>{{portfolio?.userSubCat}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row" *ngIf="portfolio?.roleName==serviceProvider || portfolio?.roleName==adminRole">
              <div class="col-md-12">
                <div class="section-title">
                  <span *ngIf="portfolio?.experienceLookUp"><b>Years of Experience: </b>{{portfolio?.experienceLookUp}},</span> <span *ngIf="portfolio?.salary"> <b>{{expectedSalary}}: </b>{{specialLookup?.currencySymbol}} {{portfolio?.salary}}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section-wrapper section-education" *ngIf="portfolio?.educations">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title"><h2>Education</h2></div>
              </div>
            </div>
            <div class="row" *ngFor="let ed of portfolio?.educations">
              <div class="col-md-12">
                <div class="content-item">
                  <h3>
                    {{ed?.diplomaName}}
                  </h3>
                  <p>{{ed?.subjectName}}</p>
                  <small *ngIf="ed.fromYear !=undefined">{{ed.fromYear}}-{{ed?.toYear}}</small>
                  <h4>{{ed.institute}}</h4>
                  <p class="mb-2">{{ed.countryCode}}</p>
                </div>
              </div>
            </div>
          </div>


        </section>



      </div>

    </div>

  </div>

</div>



