import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticatedUser } from '../model/authenticated-user';
@Injectable({
  providedIn: 'root'
})
export class ServieupdateService {
  private isAuth: boolean;
  private user!: AuthenticatedUser;
  private auth = new BehaviorSubject<boolean>(false);
  private authUser = new BehaviorSubject<AuthenticatedUser>(this.user);
  newAuthUser = this.authUser.asObservable();
  newAuth = this.auth.asObservable();

  constructor() { }

  public isAuthenticated(userName) {
    var isEmpty = userName != undefined && userName == '' ? false : true;
    this.auth.next(isEmpty);
  }

  public getAuthenticatedUser(newUser) {
    this.authUser.next(newUser);
  }
}
