import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientIP } from '../constants/api-portal-constant';

@Injectable({
  providedIn: 'root'
})
export class IpServiceService {

  constructor(private httpClient: HttpClient) { }

  public getIPAddress() {
    var protocol = document.location.protocol.replace(":", "");
    var url = "";
    switch (protocol) {
      case "https":
        url = ClientIP.SecuredURL;
        break;
      case "http":
        url = ClientIP.UnSecuredURL;
        break;
    }
    return this.httpClient.get(url);
  }
}
