
<div class="card border-0 p-0 w-100">
  <div class="card-body p-0">
    <form [formGroup]="reviewForm" (ngSubmit)="saveRating()" novalidate>
      <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
     
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-12 col-md-4 col-lg-3 label"><b>Select your {{basicProfile?.categoryName}} for reviw</b></label>

        <ng-select [items]="basicProfile?.jobProviderModel"
                   bindLabel="name"
                   bindValue="id"
                   [multiple]="false"
                   placeholder="Select {{basicProfile?.categoryName}}"
                   formControlName="jobProviderId"
                   [(ngModel)]="jobProviderId"
                   (change)="selectJobProvider()"
                   class="width-dropdown col-xs-12 col-sm-7 col-md-5 col-lg-4 p-0">
        </ng-select>
      </div>
      <div class="row" *ngIf="reviews!=undefined && reviews.length>0">
        <div formArrayName="reviews" class="col-sm-12 col-xs-12 col-md-12 col-lg-12" *ngFor="let r of reviewForm.controls.reviews.controls; let i=index;">
          <div class="row" formGroupName="{{i}}">
            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-xs-12 col-md-8 col-lg-8">
                  <b>{{r.get("subjectName").value}}</b>
                </div>
              </div>
              <div class="row">
                <div *ngFor="let qq of r.get('questionnaire').value; let j=index;">
                  <div *ngFor="let q of qq; let p=index;">
                    <div class="form-group form-inline">
                      <div class="col-sm-12 col-xs-12 col-md-8 col-lg-8 p-0">
                        {{q.name | formatData:basicProfile?.categoryName : r.get("subjectName").value}}
                      </div>
                      <div class="col-sm-12 col-xs-12 col-md-4 col-lg-4 pr-0">
                        <app-rating [questionId]="q.id" [subjectId]="r.get('subjectId').value" (onRating)=onRating($event)></app-rating>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-success">
          Save
        </button>
      </div>
    </form>
  </div>
</div>





