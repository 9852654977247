import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Component({
  selector: 'app-spinnerloader',
  templateUrl: './spinnerloader.component.html',
  styleUrls: ['./spinnerloader.component.css']
})
export class SpinnerloaderComponent {
  //loading: any = this.loader.loading;
  constructor(public loader: SpinnerService) { }

  
}

