import { Component, OnInit } from '@angular/core';
import { AuthenticatedUser } from '../../model/authenticated-user';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { AuthToken } from '../../constants/api-portal-constant';
import { LocalCookieService } from '../../services/cookie/local-cookie.service';

@Component({
  selector: 'app-log-out-api',
  template: 'Log out successfully',
  styleUrls: ['./log-out-api.component.css']
})
export class LogOutApiComponent implements OnInit {
  public user: AuthenticatedUser;
  constructor(private router: Router, private loginService: LoginService,
    private localCookieService: LocalCookieService) { }

  ngOnInit() {
    this.logOut();
  }
  logOut() {

    this.loginService.logOut().subscribe(response => {
    
      if (response != null) {
        this.user = this.loginService.assignUser(null, true);
        localStorage.removeItem(AuthToken.AuthTokenKey);
        this.localCookieService.deleteRole();
        this.loginService.assignUser(null, false)
        this.router.navigate(["/"]);
        const redirectUrl = window.location.origin;
        window.location.replace(redirectUrl)
      }
    },
      _error => {
        var err = _error;
     
    });

   
  }
}
