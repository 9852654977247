<div>Carrier Site</div>
<div class="mat-elevation-z8">
  <table mat-table class="mat-elevation-z8 border-0 table-striped table-bordered" [dataSource]="dataSource">

    <ng-container matColumnDef="countryName">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont">Country name</th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element"> {{element.countryName}} </td>
      </tr>
    </ng-container>

    <ng-container matColumnDef="link">
      <tr>
        <th mat-header-cell *matHeaderCellDef class="headerFont"> URL </th>
        <td mat-cell class="rowDetailFont" *matCellDef="let element">
          <a [href]="element.link" target="_blank">{{element.companyName}}</a>
        </td>
      </tr>
    </ng-container>

   
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <!--<mat-paginator [length]="length"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="pageSizeOptions"
                 (page)="changePage($event)">
  </mat-paginator>-->
</div>









