import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticatedUser } from '../../model/authenticated-user';
import { PasswordResetModel } from '../../model/password-reset-model';
import { AuthToken } from '../../constants/api-portal-constant';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private baseUrl: string;
    private routePrefix: string = 'api/auth';
    private _routePrefixProfile = "api/profile";
    public user: AuthenticatedUser;

    constructor(private httpClient: HttpClient, private router: Router, @Inject('BASE_URL') _baseUrl: string) {
        this.baseUrl = _baseUrl;
    }

    login(credentials: any): any {

        return this.httpClient.post(this.baseUrl + this.routePrefix + "/login", credentials, {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        });
    }

    registration(credentials: any): any {
      let headersop = new HttpHeaders();
      headersop.append('Accept', 'application/json');
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/registration", credentials, { headers: headersop });
    }

    getUser(): any {
      if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
        return this.httpClient.get(this.baseUrl + this._routePrefixProfile);
        }
        else {
            this.assignUser(null, false);
        }

    }

    logOut() {
      if (localStorage.getItem(AuthToken.AuthTokenKey) != null) {
        return this.httpClient.get(this.baseUrl + this._routePrefixProfile + "/logout");
        }
    }


  assignUser(loginProfile: any, isAuthenticated: boolean): AuthenticatedUser {
    
      if (loginProfile != null) {
        this.user = <AuthenticatedUser>loginProfile;
        this.user.isAuthenticatedUser = isAuthenticated;
       
        }
        else {
            this.user = undefined;
        }

        return this.user;
    }

    editRegistration(profile: any): any {   
      return this.httpClient.post(this.baseUrl + this.routePrefix + "/edit-personal-info", profile, {
        headers: new HttpHeaders({
          "Accept": "application/json"
        })
      });
  }

  confirmEmail(userName: string, token: string): any { 
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/confirmEmail?userid=" + userName + "&code=" + token); 
  }

  forgotPassword(passpordReset: any): any {
    return this.httpClient.post(this.baseUrl + this.routePrefix + "/forgorpassword", passpordReset, {
      headers: new HttpHeaders({       
        "content-Type": "application/json",
      })
    });
  }

  resetPassword(passpordReset: any): any {
    return this.httpClient.post(this.baseUrl + this.routePrefix + "/resetpassword", passpordReset, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  }

  sendTwofactorAuthCode(credentials: any): any {
    return this.httpClient.post(this.baseUrl + this.routePrefix + "/authcode", credentials, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  }

  verifyTwoFactorAuth(userName: string, code: string): any {
    return this.httpClient.get(this.baseUrl + this.routePrefix + "/auth-verification/" + userName + "/" + code);
  }

  saveTermsAndConditions(credentials: any): any {
    let headersop = new HttpHeaders();
    headersop.append('Accept', 'application/json');
    return this.httpClient.post(this.baseUrl + this.routePrefix + "/terms-and-conditions", credentials, { headers: headersop });
  }
}
