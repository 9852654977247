<div class="card">
  <form [formGroup]="usefulLinkForm" (ngSubmit)="save()" novalidate>
    <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
    <h3 class="regblock"><i class="fa fa-briefcase"> Carrier Site</i></h3>
    <div class="form-group form-inline">
      <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2 text-left">Link</label>
      <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
             formControlName="link"
             id="link" />
    </div>
    <div class="form-group form-inline">
      <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2 text-left">Company Name</label>
      <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
             formControlName="companyName"
             id="companyName" />
    </div>
    <div class="form-group form-inline">
      <label class="col-xs-12 col-sm-5 col-md-2 col-lg-2">Country</label>
      <ng-select class="col-xs-12 col-sm-7 col-md-5 col-lg-4 p-0" id="country"
                 [items]="allCountry"
                 bindLabel="countryName"
                 bindValue="countryCode"
                 placeholder="Select Country"
                 formControlName="countryCode"
                 [(ngModel)]="countryCode">
      </ng-select>

    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary">
        Save
      </button>
    </div>
  </form>

  <app-carriersite [dataSource]="data"></app-carriersite>
</div>


