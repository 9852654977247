
<div class="container">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-lx-12 py-3 carousel slide">
     
      <ngb-carousel *ngIf="event_list" [showNavigationIndicators]="true"  [pauseOnHover]="true" [showNavigationArrows]="true">
        <ng-template ngbSlide *ngFor="let event of upcoming_events">
          <img [src]="event.img" class="w-100"  alt="Random Slid" />
          <div class="carousel-item">
          <!--<div class="text">
            <p>{{event.description}}</p>
            <!--<p>{{event.img}}</p>-->
            <p [innerText]="event.description"></p>
          </div>

        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>
