import { Component, Input, OnInit } from '@angular/core';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { Toppers } from '../../model/toppers';
import { HttpStatus, AuthToken, Columns } from '../../constants/api-portal-constant';
import { Router } from '@angular/router';
import { IpServiceService } from '../../services/ip-service.service';

@Component({
  selector: 'app-homepage-info',
  templateUrl: './homepage-info.component.html',
  styleUrls: ['./homepage-info.component.css']
})
export class HomepageInfoComponent implements OnInit {
  //public topserviceProviders: Toppers[];
  public col: number = Columns.Col;
  public isNewRow: Boolean = true;
  //public totRow: number[];
  private ipAddress: string;
  constructor(private providersService: ProvidersService,
    private ipService: IpServiceService,
    private router: Router) { }

  ngOnInit() {
    //this.getIP();
  }
  @Input() topserviceProviders: Toppers[];
  @Input() totRow: number[];
  getTopServiceProviders() {

    this.providersService.getTopServiceProviders(this.ipAddress)
      .subscribe((data: Toppers[]) => {
        this.topserviceProviders = data;
        
        var rows: number;
        if (data.length > 0) {
          if (data.length % Columns.Col == 0) {
            rows= data.length / Columns.Col;
          }
          else {
            rows = (data.length-1) / Columns.Col + 1;
          }
          var j = 0;
          for (let i = 0; i < rows; i++) {
            j++;
            if (this.totRow != undefined) {
              this.totRow.push(j);
            }
            else {
              this.totRow = [j];
            }
            
          }

        }
      },
        err => {
          if (err.status == HttpStatus.NotFound) {
            localStorage.removeItem(AuthToken.AuthTokenKey);
            this.router.navigate(["/"]);
            const redirectUrl = window.location.origin;
            window.location.replace(redirectUrl);
          }
        });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      this.getTopServiceProviders();
      
    });
  }
}
