
<mdb-card class="cardwith card-css">
  <mdb-card-header class="info-color white-text text-center py-4">
    <h5>
      <strong>Reset your password</strong>
    </h5>
  </mdb-card-header>
  <mdb-card-body class="px-lg-5 pt-0">
    <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()" novalidate>
      <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
      <div class="md-form">
        <label for="materialNewPassword" class="label">Password</label>
        <input class="form-control"
               formControlName="newPassword"
               type="password"
               id="newPassword" mdbInput />
      </div>
      <div id="errPassword"></div>
     

      <div class="md-form mb-2">
        <label for="confirmPassword">Confirm password</label>
        <input class="form-control"
               formControlName="confirmNewPassword"
               type="password"
               id="confirmNewPassword" mdbInput />
      </div>
     
      <div id="confirmErrPassword"></div>
      <button class="btn btn-primary" type="submit">
        Submit
      </button>    
    </form>
  </mdb-card-body>
</mdb-card>



