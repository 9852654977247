<div class="">
  <br />
  <mat-toolbar color="primary" class="example-toolbar">
    <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
    <h1 class="example-app-name">{{mailBox}}</h1>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav"
                 role="navigation"
                 mode="side"
                 opened
                 position="start" style="min-height:150px;">

      <mat-nav-list>
        <a mat-list-item [routerLink]="" (click)="composeEmail(1)"><i class="fas fa-pencil-alt fa-1x" aria-hidden="true"></i></a>
        <a mat-list-item [routerLink]="" (click)="getMailBox('I')">Inbox</a>
        <a mat-list-item [routerLink]="" (click)="getMailBox('S')">Sent Items</a>
        <a mat-list-item [routerLink]="" (click)="getMailBox('O')">Outbox</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <!--<mat-toolbar color="primary">
    <button type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            *ngIf="isHandset$ | async">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <h1 class="app-name">Conversion</h1>
  </mat-toolbar>-->
      
      <app-email-details [mailboxType]="mailBoxType" [messageId]="messageId" *ngIf="mailDetails !=undefined"></app-email-details>
      <app-compose-email [fullName]="fullName" [mailToUserId]="mailToUserId" [isPrepoluatedName]="isPrepolutedFullName" *ngIf="fullName !=undefined"></app-compose-email>

      <div class="mat-elevation-z8" *ngIf="mailboxItem !=undefined">
        <table mat-table class="mat-elevation-z8 border-0 table-striped" [dataSource]="ELEMENT_DATA">
          <ng-container matColumnDef="mailToName">
            <tr>
              <td mat-cell *matCellDef="let element"><a [routerLink]="" (click)="openMail(element.messageId, element.mailBoxType)" class="column-text-decoration {{element.fontWeight}}">{{element.mailToName}}</a></td>
            </tr>
          </ng-container>

          <ng-container matColumnDef="subject">
            <tr>
              <td mat-cell class="rowDetailFont" *matCellDef="let element"><a [routerLink]="" (click)="openMail(element.messageId, element.mailBoxType)" class="column-text-decoration {{element.fontWeight}}">{{element.subject}}</a> </td>
            </tr>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
        <mat-paginator [length]="length"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       (page)="changePage($event)">
        </mat-paginator>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>

