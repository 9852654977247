export const PortalRole = {
  Admin: 'Admin',
  JobProvider: 'JobProvider',
  JobSeeker: 'JobSeeker',
  Role:'Role'
};

export const UserType =
{
  Professional: 'P',
  Student: 'S'
};

export const FromToYear =
{
  From: 'From',
  To: 'To'
};

export const MailType =
{
  Inbox: 'I',
  SentItem: 'S',
  OutBox: 'O'
};
export const AuthToken =
{
  AuthTokenKey: "authtokenkey"
};

export const CountryCode =
{
  Bangladesh: "BGD"
};

export const categoryConst =
{
  TurotTR: 'TR',
  TurotTS: 'TS',
};

export const EmailTemplate =
{
  Subscription: "Subscription",
  RecoveryUser: "RecoveryUser",
  NewHire: "NewHire",
  GeneralEmail: "GeneralEmail",
  EditProfile:"EditProfile"
};
export const ServiceStopCause =
{
  Other: "Other"
};

export const ControlConstant =
{
  District: "district",
  Division: "division",
  Thana: "thana",
  RoadnumName: "roadnumName",
  DistrictToolbar: "districttoolbar",
  DivisionToolbar: "divisionToolbar",
  ThanaToolbar: "thanaToolbar",
  RoadnumToolbar: "roadnumToolbar",
  UniversityLevel: "universityLevel",
  EnglishMedium: "englishMedium",
  Secondary: "secondary",
  Highersecondary: "highersecondary",
  Primary: "primary",
  SalaryRange: 'salaryRange',
  ExpectedSalary:"expectedSalary"
};

export const HttpStatus = {
  NotFound: 401
};

export const Columns = {
  Col:2
}; 

export const Browsers = {
  SecurityCode: "SecurityCode"
};

export const ClientIP = {
  SecuredURL:"https://api.ipify.org/?format=json",
  UnSecuredURL: "http://api.ipify.org/?format=json"
};

export const TinyMCE =
{
  ApiKey: "u370vgj6e4u0hrmc7sz9zrjm0wljblju3qbvjfmxvl81ndxs"
};

export const PaymentType = {
  CreditCard: "CreditCard",
  Bikash:"Bikash"
};
