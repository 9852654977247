<mdb-card class="cardwith card-css">
  <mdb-card-header class="py-0 b-0">
    <h5>
      {{composeMail?.subject}}
    </h5>
  </mdb-card-header>
  <mdb-card-body class="px-lg-5 pt-0">
    <div class="d-flex flex-row">
      <div class="p-8"> <p class="body-font" [innerHTML]="composeMail?.mailToName"></p></div>
    </div>
    <div class="d-flex flex-row">
      <div class="p-8"> <p class="body-font" [innerHTML]="composeMail?.body"></p></div>
    </div>

  </mdb-card-body>
</mdb-card>
