<div id="modal-content-wrapper">
  <header id="modal-header">
    <button type="button" class="close border-0" data-dismiss="modal" style="outline: none;" (click)="closeModal()">&times;</button>
    <h1 id="modal-title">Terms and Conditions</h1>
  </header>
  <section id="modal-body verticalScroll">
    <!--<div class="body-font" [innerHTML]="termsandconditionmodel?.termsConditionText"></div>-->
    <div [innerHtml]="termsandconditionmodel?.termsConditionText"></div>
   
  </section>
  <footer id="modal-footer">

    <!--<button mat-raised-button id="modal-cancel-button" (click)="closeModal()">-->
    <button mat-raised-button class="btn btn-primary" (click)="closeModal()">
      Ok
    </button>
  </footer>
</div>
