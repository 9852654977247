<br /><br />
<div class="card border-0 p-0 w-100">
  <div class="card-header text-center card-header-bgk"><h1>{{fullName}}</h1></div>
  <div class="card-body">
    <form [formGroup]="hireMeForm" (ngSubmit)="saveHire()" novalidate>
      <app-portal-message [successMsg]="successMsg" [errMsg]="errMessage"></app-portal-message>
      <h3 class="regblock"> <i class="fa fa-shield">&nbsp;Basic Information</i></h3>
      <hr />
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">First Name </label>
        <input class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
               formControlName="firstName" disabled
               id="firstName" />
      </div>
      <div *ngIf="hireMeForm.get('firstName').errors && isSubmitted" class="alert alert-danger">
        <div *ngIf="hireMeForm.get('firstName').hasError('required')">
          First name is required.
        </div>
        <div *ngIf="hireMeForm.get('firstName').hasError('minlength')">
          First name required at least 4 characters.
        </div>

        <div *ngIf="hireMeForm.get('firstName').hasError('maxlength')">
          Max 80 characters are allowed for first name.
        </div>
      </div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Last Name</label>
        <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
               formControlName="lastName" disabled
               id="lastName" />
      </div>

      <div *ngIf="hireMeForm.get('lastName').errors && isSubmitted" class="alert alert-danger">
        <div *ngIf="hireMeForm.get('lastName').hasError('required')">
          Last name is required.
        </div>
        <div *ngIf="hireMeForm.get('lastName').hasError('minlength')">
          Last name required at least 4 characters.
        </div>

        <div *ngIf="hireMeForm.get('lastName').hasError('maxlength')">
          Max 80 characters are allowed for last name.
        </div>
      </div>
      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Subjects</label>
        <ng-select [items]="subjects"
                   bindLabel="name"
                   bindValue="id"
                   [multiple]="true"
                    groupBy="groupName"
                   placeholder="Select Subjects"
                   formControlName="selectedCatSubIds"
                   [(ngModel)]="selectedCatSubIds"
                   class="col-xs-12 col-sm-7 col-md-5 col-lg-4 p-0">
        </ng-select>
      </div>

      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Salary</label>
        <input type="number" min="0" value="0" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
               formControlName="salary"
               id="salary" />
      </div>
      <div *ngIf="hireMeForm.get('salary').errors && isSubmitted" class="alert alert-danger">
        <div *ngIf="hireMeForm.get('salary').hasError('required')">
          Salary is required.
        </div>

        <div *ngIf="hireMeForm.get('salary').hasError('pattern')">
          Invalid Salary number.
        </div>
      </div>

      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2 label">Hire date</label>
        <ng-select [items]="allMonths"
                   bindLabel="monthName"
                   bindValue="id"
                   [multiple]="false"
                   placeholder="Select Month"
                   formControlName="selectedMonth"
                   [(ngModel)]="selectedMonth"
                   (change)="filterDays()"
                   class="width-dropdown">
        </ng-select>
        <ng-select [items]="allDays"
                   bindLabel="day"
                   bindValue="id"
                   [multiple]="false"
                   placeholder="Select Day"
                   formControlName="selectedDay"
                   [(ngModel)]="selectedDay"
                   class="width-dropdown">
        </ng-select>
        <ng-select [items]="allYears"
                   bindLabel="yearName"
                   bindValue="id"
                   placeholder="Select Year"
                   formControlName="selectedYear"
                   [multiple]="false"
                   [(ngModel)]="selectedYear"
                   class="width-dropdown">
        </ng-select>
      </div>

      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">City</label>
        <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
               formControlName="city" disabled
               id="city" />
      </div>

      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Thana</label>
        <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
               formControlName="thanaName"
               id="thanaName" disabled />
      </div>

      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">District</label>
        <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
               formControlName="districtName"
               id="districtName" disabled />
      </div>

      <div class="form-group form-inline">
        <label class="col-xs-12 col-sm-5 col-md-3 col-lg-2">Division</label>
        <input type="text" class="form-control col-xs-12 col-sm-7 col-md-5 col-lg-4"
               formControlName="divisionName"
               id="divisionName" disabled />
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-success">
          Save
        </button>
      </div>
    </form>
  </div>
</div>



