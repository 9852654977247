<br />
<br />
<h1 class="body-font" [innerHTML]="title"></h1>
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0" *ngFor="let w  of whatToDoModel">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="card w-100  border-0 p-0">
      <div class="card-body p-0">
        <p class="body-font" [innerHTML]="w.message"></p>
      </div>
    </div>
  </div>
</div>

