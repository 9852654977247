import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newRorOrCol'
})
export class NewRorOrColPipe implements PipeTransform {

  transform(col: number, value: number, val2:any): any {
   
    if (value <= col) {     
      value = value + 1;
    }
    else {
      value = 1;
    }
    return value;
  }


}
