import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { ServiceProvider } from '../../model/service-provider';
import { Portfolio } from '../../implementations/portfolio';
import { ControlConstant, PortalRole } from '../../constants/api-portal-constant';
import { SpecialLookup } from '../../model/special-lookup';
import { IpServiceService } from '../../services/ip-service.service';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { SetBrowserService } from '../../services/set-browser.service';
import { LocalCookieService } from '../../services/cookie/local-cookie.service';
import { Title } from '@angular/platform-browser';
import { LabelByCountryModel } from '../../model/label-by-country-model';
import { CoreService } from '../../services/core/core.service';

@Component({
  selector: 'app-serviceprovidercontact',
  templateUrl: './serviceprovidercontact.component.html',
  styleUrls: ['./serviceprovidercontact.component.css']
})
export class ServiceprovidercontactComponent implements OnInit {

  public invalidLogin: boolean;
  public portfolio: Portfolio;
  public userTitle: string;
  public requirement: string;
  public userSubCat: string;
  public userId: any;
  public serviceProvider = PortalRole.JobProvider;
  public jobSeeker = PortalRole.JobSeeker;
  public adminRole = PortalRole.Admin;
  public specialLookup: SpecialLookup;
  public userType: string;
  public enableDisable: string;
  public labelByCountry: LabelByCountryModel[];
  public expectedSalary: string;

  constructor(private router: Router,
    private Activatedroute: ActivatedRoute,
    private providersService: ProvidersService,
    private ipService: IpServiceService,
    private setbrowserService: SetBrowserService,
    private portalnInfo: PortalninformationService,
    private localCookieService: LocalCookieService,
    private title: Title,
    private coreService: CoreService) {

    this.userId = this.Activatedroute.snapshot.queryParamMap.get('userId') || 0;
    this.userType = this.localCookieService.getRole();
  }

  ngOnInit() {
    this.title.setTitle("Resume-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.getIP(); 
   
  }

  getProviderProfile() {
    this.providersService.getProvierProfile(this.userId)
      .subscribe((data: Portfolio) => {
        this.portfolio = data;
        this.title.setTitle(`${this.portfolio.firstName} ${this.portfolio.lastName}'s resume-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh`);
        if (this.portfolio.isHired) {
          this.enableDisable = "disabled";
        }       
             
      });
  }
  getImageUrl(imageUrl: string) {
    return ("assets/" + imageUrl);
  }
  navigatetoEmail() {
    //debugger;
    var fullName = this.portfolio.firstName + " " + this.portfolio.lastName;
    this.router.navigate(["/conversation"], { queryParams: { userId: this.userId, fullName: fullName } });
  }

  getPortalInfo(ip: string) {
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
        this.getLabelByCountry(this.specialLookup.actualCountryCode);
      });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      var ipAddress = res.ip;
      this.getPortalInfo(ipAddress);
    });
  }

  navigatetoHire() {
    this.router.navigate(["/hireme"], {
      queryParams: {
        userId: this.userId }
    });
  }

  navigatetoStopService() {
    this.router.navigate(["/stopservice"], {
      queryParams: {
        userId: this.userId
      }
    });
  }

  getLabelByCountry(countryCode: string) {
    this.coreService.getLabelByCountry(countryCode, this.userType)
      .subscribe((data: LabelByCountryModel[]) => {
        this.labelByCountry = data;
        var localControl = this.labelByCountry.filter(x => x.countryCode ==
          this.specialLookup.actualCountryCode && x.controlName == ControlConstant.ExpectedSalary);
        if (localControl && localControl.length > 0) {
          this.expectedSalary = localControl[0].labelName;
        }
        this.getProviderProfile();

      });
  }
}
