import { Component, OnInit } from '@angular/core';
import { SpecialLookup } from '../../model/special-lookup';
import { Subjects } from '../../model/subjects';
import { HiredModel } from '../../model/hired-model';
import { Daymodel } from '../../model/daymodel';
import { Monthmodel } from '../../model/monthmodel';
import { Yearmodel } from '../../model/yearmodel';
import { FormControl, Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalninformationService } from '../../services/portalInfo/portalninformation.service';
import { ProvidersService } from '../../services/serviceproviders/providers.service';
import { SetBrowserService } from '../../services/set-browser.service';
import { IpServiceService } from '../../services/ip-service.service';
import { CoreService } from '../../services/core/core.service';
import { ReviewingsModel } from '../../model/reviewings-model';
import { JobProviderModel } from '../../model/job-provider-model';
import { BasicProfile } from '../../model/basic-profile';
import { QuestionnaireModel } from '../../model/questionnaire-model';
import { QuestionModel } from '../../model/question-model';
import { LocalCookieService } from '../../services/cookie/local-cookie.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ratings',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
  public reviewForm: UntypedFormGroup;
  public userId: any;
  public userType: string;
  public specialLookup: SpecialLookup;
  public reviews: ReviewingsModel[];
  public jobProviderId: number;  
  public jobProviderModel: JobProviderModel[];
  public basicProfile: BasicProfile;
  public currentRate = 0;
  public dynamicForm: UntypedFormGroup;
  public submitted = false;
  private questionnaire: UntypedFormArray;
  totalstar = 5;
  public successMsg: string;
  public errMessage: string;

  constructor(private fb: UntypedFormBuilder,
    private coreService: CoreService,
    private providersService: ProvidersService,
    private ipService: IpServiceService,
    private setbrowserService: SetBrowserService,
    private portalnInfo: PortalninformationService,
    private activatedroute: ActivatedRoute,
    private localCookieService: LocalCookieService,
    private title: Title) {

    this.createForm();
    this.userId = this.activatedroute.snapshot.queryParamMap.get('userId') || 0;
    this.userType = this.localCookieService.getRole();
  }

  ngOnInit() {
    this.title.setTitle("Reviews-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.getIP();
    this.getJobProviders(); 
  }
  createForm() {
    this.reviewForm = this.fb.group({
      jobProviderId: Number,
      reviews: new UntypedFormArray([])     
    });
  }

  saveRating() {
    this.submitted = true;    
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.reviewForm.value, null, 4));
    let credentials1 = JSON.stringify(this.reviewForm.value);
    var fileData = new FormData();   
    fileData.append('data', credentials1);
    let credentials =fileData;
    if (credentials != undefined) {
      this.providersService.saveReview(credentials).subscribe(response => {
        let info = (<any>response).success;
        if (info != undefined) {
          this.successMsg = info;
          this.errMessage = null;
        }
        else {
          this.errMessage = response.errMsg;
          this.successMsg = null;
        }
      }, err => {

      });
    }

  }
  getPortalInfo(ip: string) {
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
      });
  }

  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      var ipAddress = res.ip;
      this.getPortalInfo(ipAddress);
    });
  }

  getServiceProviderProfile() {
    var filter = <BasicProfile>({
      userId: this.basicProfile.userId, userType: this.basicProfile.userType,
      jobProviderUserId: this.jobProviderId
    });
    this.providersService.getReviews(filter)
      .subscribe((data: ReviewingsModel[]) => {      
      
        this.onChangeJobProvider(data);    
        
      });
  }

  getJobProviders() {
    this.providersService.getJobProviders()
      .subscribe((data: BasicProfile) => {        
        this.basicProfile = data;
      });
  }
  selectJobProvider() {
    this.getServiceProviderProfile();
  }
  replaceVale(val: string) {
    return val.replace("{0}", this.basicProfile.categoryName);
  }

  get f() { return this.reviewForm.controls; }
  get t() { return this.f.reviews as UntypedFormArray; }
  
 
  onChangeJobProvider(e: ReviewingsModel[]) {
  
    for (let i = 0; i < e.length; i++) {
      var subject = e[i];
      this.addQuestion(subject.questionnaireModel);
     
      this.t.push(this.fb.group({
        subjectId: [subject.subjectId],
        subjectName: [subject.subjectName],
        tutoringSubjectId: [subject.tutoringSubjectId],
        questionnaire: this.fb.array([this.questionnaire])
      }));
    
    }
    this.reviews = e;
  }
   
  newQuestion(question: QuestionnaireModel): UntypedFormGroup {
    return this.fb.group({
      id: [question.id],
      name: [question.name],
      ratingValue: [question.ratingValue]
    });
  }

  addQuestion(questionnaireModel: QuestionnaireModel[]) {   
    this.questionnaire = new UntypedFormArray([]);
    for (var i = 0; i < questionnaireModel.length; i++) {
      var question = questionnaireModel[i];   
      this.questionnaire.push(this.newQuestion(question));
    }
  }
  stars: number[] = [1,2,3,4,5];
  

  public onRating(rating: QuestionModel): void {   
    var subIndx: number = 0;
    for (let r of this.reviewForm.value.reviews) {
      //debugger
      var qsIndx: number = 0;
      if (r.subjectId == rating.subjectId) {       
        for (let allQs of r.questionnaire) {
          for (let q of allQs) {
            if (q.id == rating.questionId) {
             // debugger
              this.reviewForm.value.reviews[subIndx].questionnaire[0][qsIndx].ratingValue = rating.ratingValue;
              qsIndx = 0;
              subIndx = 0;
              break;
            }
            qsIndx++;
          }
         
         
        }
      
      }
      subIndx++;
    }
  }
}
