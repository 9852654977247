import { Component, OnInit, HostListener  } from '@angular/core';
import { PortalninformationService } from '../services/portalInfo/portalninformation.service';
import { IpServiceService } from '../services/ip-service.service';
import { SpecialLookup } from '../model/special-lookup';
import { Homepagemodel } from '../model/homepagemodel';
import { AuthToken, Columns, HttpStatus } from '../constants/api-portal-constant';
import { Title } from '@angular/platform-browser';
import { AdminService } from '../services/Admin/admin.service';
import { Eventsmodel } from '../model/eventsmodel';
import { Toppers } from '../model/toppers';
import { ProvidersService } from '../services/serviceproviders/providers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
 
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit{
  private ipAddress: string;
  public specialLookup: SpecialLookup;
  public events: Eventsmodel[] | undefined;
  public homepagemodel: Homepagemodel;
  public isAuthenticated: boolean = false;
  public noSeviceMessage: any | undefined;
  public topserviceProviders: Toppers[];
  public col: number = Columns.Col;
 
  public isNewRow: Boolean = true;
  public totRow: number[];
  constructor(private portalnInfo: PortalninformationService,
    private ipService: IpServiceService, private title: Title,
    private adminService: AdminService, private providersService: ProvidersService, private router: Router) {
  }
  ngOnInit(): void {
    this.title.setTitle("Home-Tutor,Tuition, Programming Instructor,Beauty Instructor, Online Tutor Bangladesh");
    this.getIP();
    if (localStorage.getItem(AuthToken.AuthTokenKey)) {
      this.isAuthenticated = true;
    }
  }
  getIHomePageInformation
  GetHomePageInfo() {
   // debugger;
    //if (this.specialLookup.actualCountryCode != undefined) {
    //  this.portalnInfo.getIHomePageInformation(this.specialLookup.actualCountryCode)
    //    .subscribe((data: Homepagemodel) => {
    //      this.homepagemodel = data;
    //    });
    //}
  }
  
  getIP() {
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      this.getPortalInfo(this.ipAddress);
      this.getEvents();
      this.getCountryNoServiceMessage();
      this.getTopServiceProviders();
    });
  }
  getPortalInfo(ip: string) {
  //  debugger;
    this.portalnInfo.getPortalInformation(ip)
      .subscribe((data: SpecialLookup) => {
        this.specialLookup = data;
        this.GetHomePageInfo();
      });
  }
  getTopServiceProviders() {

    this.providersService.getTopServiceProviders(this.ipAddress)
      .subscribe((data: Toppers[]) => {
        this.topserviceProviders = data;
        var rows: number;
        if (data.length > 0) {
          if (data.length % Columns.Col == 0) {
            rows = data.length / Columns.Col;
          }
          else {
            rows = (data.length - 1) / Columns.Col + 1;
          }
          var j = 0;
          for (let i = 0; i < rows; i++) {
            j++;
            if (this.totRow != undefined) {
              this.totRow.push(j);
            }
            else {
              this.totRow = [j];
            }

          }

        }
      },
        err => {
          if (err.status == HttpStatus.NotFound) {
            localStorage.removeItem(AuthToken.AuthTokenKey);
            this.router.navigate(["/"]);
            const redirectUrl = window.location.origin;
            window.location.replace(redirectUrl);
          }
        });
  }
  getEvents() {
    this.adminService.getEvents(this.ipAddress)
      .subscribe((data: Eventsmodel[]) => {
        this.events = data;
        
      });
  }

  getCountryNoServiceMessage() {
    this.adminService.getCountryNoServiceMessage(this.ipAddress)
      .subscribe(data => {
        this.noSeviceMessage = data;

      });
  }
}
