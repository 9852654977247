import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VideoplayerService {

  private baseUrl: string;
  private routePrefix: string = 'api/video';

  constructor(private httpClient: HttpClient, private router: Router, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl;
  }

  getAllFeatureLectures(): any {
  
    return this.httpClient.get(`${this.baseUrl}${this.routePrefix}`);
  }
}
